import MotionBase from "../../lib/MotionBase";
import AspectFitter from "../../lib/AspectFitter";
import { addClass, removeClass } from "../../common/common";
import { onMount } from "solid-js";

// import { userData } from "../js/UserData";
import "./map.css";

function Check({ stat }) {
  const nextPage = "Test";
  /**
   * タイトル後のページを設定する
   */
  onMount(() => {});

  return (
    <MotionBase>
      <div class="absoluteCenter show h-full w-full flexCenter bg-white">
        <div class="h-full w-full flexCenter fitScreenY">
          <div class="w-full h-full eGrid-row-[1108,830,278]">
            <div class="h-full w-full flex justify-center items-center flex-col">
              <p class="text-5xl font-noto font-black text-center m-12">
                イヤホンを <br /> 付けてください
              </p>
              <img
                class="w-per-[614] h-auto"
                src="./images/check/check.png"
                alt=""
              />
            </div>
            <div class="flex justify-center items-center w-full h-full">
              <div class="flex justify-around items-center flex-col w-full h-[80%]">
                <div class="w-per-[502]">
                  <AspectFitter w={502} h={110}>
                    <button
                      class="btn3D btn-main w-full h-full"
                      onclick={(ev) => {
                        removeClass(ev.target, "pointer-events-auto");
                        stat.changePage(nextPage);
                      }}
                    >
                      <p class="text-customBase text-5xl font-noto font-black">
                        付けた
                      </p>
                    </button>
                  </AspectFitter>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MotionBase>
  );
}

export default Check;
