import { Presence } from "@motionone/solid";
import { onMount, Show } from "solid-js";
import { Dynamic } from "solid-js/web";
import LoadingScreen from "./lib/LoadingScreen";

import { pages, modals } from "./pageSet";

function App({ ...stat }) {
  /**
   * @type {HTMLElement}
   */
  let appRoot = undefined;
  onMount(() => {
    if (matchMedia("(hover: none)")) {
      // alert("hover:none");
      // appRoot.setAttribute("ontouchstart", "");
    }
  });

  return (
    <div ref={appRoot} class="relative h-full w-full overflow-hidden">
      <div class="absolute top-0 left-0 right-0 bottom-0 h-full w-full overflow-hidden">
        <Show when={stat.state.isLoading.value} fallback={<></>}>
          <LoadingScreen />
        </Show>

        <div class="absolute top-0 left-0  w-full h-full">
          <Dynamic
            component={pages[stat.pageState.currentPage.value]}
            stat={stat}
          />
        </div>

        <Presence exitBeforeEnter>
          <For each={Object.entries(modals)}>
            {([key, value], index) => {
              return (
                <Presence exitBeforeEnter>
                  <Show
                    when={stat.pageState.popups[key].value}
                    fallback={<></>}
                  >
                    {value(stat)}
                  </Show>
                </Presence>
              );
            }}
          </For>
        </Presence>
      </div>
    </div>
  );
}

export default App;
