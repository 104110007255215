import { createEffect, onMount } from "solid-js";
import myConfig, { targetLoc } from "../../pageConfig";
import { initHowler } from "../../lib/Howler";

const Loading = ({ stat }) => {
  const DefaultPage =
    stat.state.jumpParam.value == undefined
      ? "Title"
      : stat.state.jumpParam.value;

  // const NextPage = myConfig.DebugMode ? myConfig.DebugInitPage : DefaultPage;
  const NextPage = DefaultPage;

  function makeSoundList() {
    // 音とファイル設定
    const musicNames = [];
    for (const key in targetLoc) {
      if (targetLoc.hasOwnProperty(key)) {
        if (targetLoc[key].posName != undefined) musicNames.push(key + "_info");
        if (targetLoc[key].nestName != undefined)
          musicNames.push(key + "_route");
      }
    }
    // ファイル設定
    stat.state.sounds.value = musicNames;
  }

  function checkLoadStorage() {
    let storedData = null;

    try {
      // ローカルストレージからデータを取得
      storedData = localStorage.getItem("spot_data");
    } catch (e) {
      console.error("ローカルストレージの取得中にエラーが発生しました:", e);
    }

    if (storedData === null) {
      console.log("データをロードしませんでした。");
      return;
    }

    // データをオブジェクトに変換
    const userInfo = JSON.parse(storedData);
    console.log("ローカルストレージからデータを読み込みました:", userInfo);
    stat.state.saveData.value = userInfo;

    // 通過した数によるイベント
    function countPassedTargets() {
      let count = 0;
      for (const key in stat.state.spots.value) {
        if (stat.state.spots.value.hasOwnProperty(key)) {
          if (stat.state.spots.value[key].isPassed) count++;
        }
      }
      return count;
    }

    //特殊ケースの際、一部変更
    for (const key in userInfo) {
      if (userInfo.hasOwnProperty(key)) {
        stat.state.spots.value[key].isPassed = userInfo[key];
        if (key == "waterfall") stat.state.spots.value["route01"].show = true;
      }
    }
    //特殊ケースの際、一部変更
    const count = countPassedTargets();
    if (count == Object.keys(stat.state.spots.value).length - 1) {
      stat.state.spots.value["museum"].show = true;
    }
  }

  function recoveryStorage() {
    // ローカルストレージからデータを取得
    const storedData = stat.state.recoveryPoint.value;
    // console.log(storedData);

    if (storedData === null) return;
    // デコードしてオブジェクトに変換

    const keys = Object.keys(targetLoc);
    // 入力パラメータを配列に分解
    const values = storedData.split("").map((value) => value === "1");
    // キーと値を対応させてオブジェクトを作成
    const userInfo = keys.reduce((obj, key, index) => {
      obj[key] = values[index] || false; // 値がない場合は`false`をデフォルト
      return obj;
    }, {});
    console.log("URLからデータを読み込みました:", userInfo);
    stat.state.saveData.value = userInfo;

    // 通過した数によるイベント
    function countPassedTargets() {
      let count = 0;
      for (const key in stat.state.spots.value) {
        if (stat.state.spots.value.hasOwnProperty(key)) {
          if (stat.state.spots.value[key].isPassed && userInfo[key]) count++;
        }
      }
      return count;
    }

    //特殊ケースの際、一部変更
    for (const key in userInfo) {
      if (userInfo.hasOwnProperty(key)) {
        stat.state.spots.value[key].isPassed = userInfo[key];
        if (key == "waterfall" && userInfo[key])
          stat.state.spots.value["route01"].show = true;
      }
    }
    //特殊ケースの際、一部変更
    const count = countPassedTargets();
    if (count == Object.keys(stat.state.spots.value).length - 1) {
      stat.state.spots.value["museum"].show = true;
    }
  }

  onMount(() => {
    stat.state.isLoading.value = true;
    // ホラーの設定
    makeSoundList();
    initHowler();

    // 復帰ポイント
    if (stat.state.recoveryPoint.value == undefined) {
      checkLoadStorage();
    } else {
      recoveryStorage();
    }
    stat.state.isLoading.value = false;
  });
  createEffect(() => {
    if (!stat.state.isLoading.value) {
      stat.changePage(NextPage);
    } else {
    }
  });
};
export default Loading;
