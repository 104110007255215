// 宣言は必要
import SimpleBar from "simplebar";
import "simplebar/dist/simplebar.css";

/**
 *
 * @param { {
 * scrollbarSize:string,
 * id?:string,
 * trackColor?:string,
 * thumbColor?:string,
 * children: JSX.Element | ((item: NonNullable<T>) => JSX.Element)
 * }} props
 * @returns {() => JSX.Element}
 */
function ScrollableArea({
  scrollbarSize = "0.375rem",
  id = "",
  trackColor = undefined,
  thumbColor = undefined,
  ...stat
}) {
  return (
    <div class="relative w-full h-full overflow-y-auto pointer-events-auto">
      <div
        id={`${id}`}
        data-simplebar
        data-simplebar-auto-hide="false"
        // iPhone7以前だとinsetが使えなく、ビルドするとtopなどの指定がinsetに統合されてしまう。なのでtop left right bottomをそれぞれクラスで指定
        class="relative w-full h-full top-0 scrollbar-custom [&_.simplebar-mask]:top-0 [&_.simplebar-mask]:left-0 [&_.simplebar-mask]:right-0 [&_.simplebar-mask]:bottom-0 [&_.simplebar-scrollbar]:before:!top-0 [&_.simplebar-scrollbar]:before: [&_.simplebar-scrollbar]:before:!right-0 [&_.simplebar-scrollbar]:before:!bottom-0 [&_.simplebar-offset]:top-0 [&_.simplebar-offset]:left-0 [&_.simplebar-offset]:bottom-0 [&_.simplebar-offset]:right-0 [&_.simplebar-track.simplebar-horizontal_.simplebar-scrollbar]:top-0 [&_.simplebar-track.simplebar-horizontal_.simplebar-scrollbar]:bottom-0 [&_.simplebar-track.simplebar-horizontal_.simplebar-scrollbar]:right-0 [&_.simplebar-track.simplebar-horizontal_.simplebar-scrollbar]:left-0"
        style={`
      --scrollbar-size:${scrollbarSize} !important;
      ${trackColor ? `--scrollbar-track:${trackColor};` : ""}
      ${thumbColor ? `--scrollbar-thumb:${thumbColor};` : ""}
      `}
      >
        <div class="relative w-full h-auto pointer-events-auto">
          {stat.children}
        </div>
      </div>
    </div>
  );
}
export default ScrollableArea;
