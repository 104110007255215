import MotionBase from "../../lib/MotionBase";
import AspectFitter from "../../lib/AspectFitter";
import { addClass, isSP, removeClass } from "../../common/common";
import { createEffect, onMount, createSignal, onCleanup } from "solid-js";

import myConfig, { targetLoc, MapPos } from "../../pageConfig";

import EllipseButtonBase3 from "../../lib/EllipseButtonBase3";

import "./map.css";
import SignalProperty from "../../common/SignalProperty";

import { observeGPS, distanceGPS } from "../../lib/GPS";
import * as TWEEN from "@tweenjs/tween.js";
import {
  initHowler,
  killAllSounds,
  make_sounds,
  stopAllSounds,
} from "../../lib/Howler";
import { sendGA } from "../../js/GA";

function Map({ stat }) {
  const nextPage = "";

  const targets = stat.state.spots.value;
  let pointerShowList = {};

  for (const key in targetLoc) {
    if (targetLoc.hasOwnProperty(key)) {
      pointerShowList[key] = targets[key].show;
    }
  }
  // setPointerShowの更新
  function updatePointerShow(key, newValue) {
    setPointerShow((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  }

  const [pointerShow, setPointerShow] = createSignal(pointerShowList);

  const [currentKey, setCurrentKey] = createSignal(undefined);
  const [currentPos, setCurrentPos] = createSignal(undefined);

  const [isFollowGPS, setIsFollowGPS] = createSignal(true);
  const [myPointer, setMyPointer] = createSignal({ x: 50, y: 50 });

  const [isShowSpot, setIsShowSpot] = createSignal(false);
  const [isShowRoute, setIsShowRoute] = createSignal(false);

  const [isComplete, setIsComplete] = createSignal(false);

  const ListVisibility = Object.keys(targetLoc);

  const mapSize = [842.3657, 1199.96];

  let lastPos = { x: 50, y: 50 };
  let tween_goPointer = undefined;
  let tween_goScroll = undefined;

  let elem_pointer = undefined;
  let elem_map = undefined;

  let mapWidth = 0;
  let mapHeight = 0;

  function test(x, y) {
    const posPP = [x, y];
    setMyPointer(fix_myPos(posPP[1], posPP[0]));
    witchPosNear(posPP[1], posPP[0]);
    if (isFollowGPS()) followGPS();
  }

  function handleTap(event) {
    // タップされた座標 (X, Y)
    const tapX = event.offsetX;
    const tapY = event.offsetY;
    // パーセンテージを計算
    const percentX = tapX / mapWidth;
    const percentY = 1 - tapY / mapHeight;
    // 座標を計算
    const lngPercent = MapPos.l_u[1] + range_lng * percentX;
    const latPercent = MapPos.r_d[0] + range_lat * percentY;

    test(latPercent, lngPercent);
  }

  function testInit() {
    // テスト登録
    window.test = test;
    elem_map.addEventListener("mousedown", handleTap);
    animeJS();
  }

  function animeJS() {
    if (isFollowGPS()) followGPS();
    setTimeout(animeJS, 1000);
  }

  const handleGPSUpdate = (ev) => {
    const lng = ev.detail.lng;
    const lat = ev.detail.lat;
    setMyPointer(fix_myPos(lng, lat));
    witchPosNear(lng, lat);
    if (isFollowGPS()) followGPS();
  };

  function init() {
    mapWidth = elem_map.scrollWidth;
    mapHeight = elem_map.scrollHeight;
  }

  function handleScroll() {
    tween_goScroll = null;
    setIsFollowGPS(false);
  }

  onMount(() => {
    init();
    if (myConfig.DebugMode) {
      testInit();
    } else {
      observeGPS();
    }

    document.addEventListener("gpsUpdate", handleGPSUpdate);
    if (isSP()) {
      elem_map.addEventListener("touchstart", handleScroll);
    } else {
      elem_map.addEventListener("mousedown", handleScroll);
    }
    tweenAnimate();
  });

  onCleanup(() => {
    document.removeEventListener("gpsUpdate", handleGPSUpdate);
    if (isSP()) {
      elem_map.removeEventListener("touchstart", handleScroll);
    } else {
      elem_map.removeEventListener("mousedown", handleScroll);
    }
    killAllSounds();
  });

  // 追尾プログラム;
  function followGPS() {
    // 初期値の時追尾しない
    if (elem_pointer.style.bottom == 0) return;

    // X座標の調整
    const leftPercentage =
      Number(elem_pointer.style.left.replace("%", "")) * 0.01;
    const targetPositionX = mapWidth * (leftPercentage - 0.25);
    // Y座標の調整
    const topPercentage =
      1 - Number(elem_pointer.style.bottom.replace("%", "")) * 0.01;
    const targetPositionY = mapHeight * (topPercentage - 0.2);
    // 変更前の位置の記録
    const scrollObject = { x: elem_map.scrollLeft, y: elem_map.scrollTop };

    tween_goScroll = new TWEEN.Tween(scrollObject)
      .to({ x: targetPositionX, y: targetPositionY }, 900) // 目的地と時間を設定
      .easing(TWEEN.Easing.Linear.None) // イージングを設定
      .onUpdate(() => {
        elem_map.scrollTop = scrollObject.y; // 縦スクロール位置を更新
        elem_map.scrollLeft = scrollObject.x; // 横スクロール位置を更新
      })
      .start(); // アニメーションを開始
  }
  // TWEEENのアニメ自動再生
  function tweenAnimate() {
    if (tween_goPointer) tween_goPointer.update();
    if (tween_goScroll) tween_goScroll.update();
    requestAnimationFrame(tweenAnimate);
  }

  function saveData(key, data) {
    stat.state.saveData.value = {
      ...stat.state.saveData.value,
      [key]: data,
    };
    // 更新したオブジェクトを再びローカルストレージに保存
    try {
      // 更新したオブジェクトを再びローカルストレージに保存
      localStorage.setItem(
        "spot_data",
        JSON.stringify(stat.state.saveData.value)
      );
      console.log("データをローカルストレージに保存しました");
    } catch (e) {
      console.error("ローカルストレージへの保存中にエラーが発生しました:", e);
    }
  }

  // 音再生
  function setSoundGo(isNext, isSpot, isNear = false) {
    if (!isNear)
      if (isSpot) {
        sendGA("ボタン押下", {
          spot_info: "click_info",
        });
      } else {
        sendGA("ボタン押下", {
          next_spot_info: "click_route",
        });
      }
    // すでに通過している場合はスキップ
    if (isNear) {
      if (stat.state.spots.value[currentKey()].isPassed) return;
      stat.state.spots.value[currentKey()].isPassed = true;
      stat.state.isInSpot.value = true;
      saveData(currentKey(), true);
      if (stat.state.spots.value[currentKey()].numOpen != undefined) {
        stat.state.spots.value[
          stat.state.spots.value[currentKey()].numOpen
        ].show = true;
        targets[stat.state.spots.value[currentKey()].numOpen].show = true;
        updatePointerShow(targets[currentKey()].numOpen, true);
      }
    } else {
      stat.state.isInSpot.value = false;
    }
    stat.pageState.popups.Music.value = false;
    stat.state.currentKey.value = currentKey();
    stat.state.currentTarget.value = currentPos();
    stat.state.isSpotMusic.value = isSpot;
    stat.state.isNextMusic.value = isNext;
    // stopAllSounds(stat.state.sounds.value);
    updateUIState(countPassedTargets());
    stat.pageState.popups.Music.value = true;
  }

  const range_lng = MapPos.r_u[1] - MapPos.l_u[1];
  const range_lat = MapPos.r_u[0] - MapPos.r_d[0];

  // 自分の位置を計算
  function fix_myPos(lng, lat) {
    if (lng == 0 || lat == 0) return { x: 50, y: 50 };
    const lngPercent = ((lng - MapPos.l_u[1]) / range_lng) * 100;
    const latPercent = ((lat - MapPos.r_d[0]) / range_lat) * 100;
    return { x: lngPercent, y: latPercent };
  }

  // マップのオブジェクトを設置
  function setPointer() {
    let obj = [];
    for (const key in targets) {
      if (targets.hasOwnProperty(key)) {
        const location = targets[key].location;
        const locationName = targets[key].posName;
        const radius = targets[key].radius * 0.4;
        const lngPercent = ((location[1] - MapPos.l_u[1]) / range_lng) * 100;
        const latPercent =
          100 - ((location[0] - MapPos.r_d[0]) / range_lat) * 100;

        const map_location = targets[key].mapPos;
        const map_locationName = targets[key].posName
          ? targets[key].posName
          : targets[key].nextPos;

        const map_xPercent = (map_location[0] / mapSize[0]) * 100;
        const map_yPercent = (map_location[1] / mapSize[1]) * 100;

        obj.push(
          <>
            {/* <p
              class="absolute center-xy pointer-events-none"
              style={`left: ${lngPercent}%; top: ${latPercent}%;`}
            >
              {locationName}
            </p> */}
            {/* <div
              key={key}
              class="absolute rounded-full bg-[rgba(255,0,0,0.3)] center-xy pointer-events-none"
              style={`left: ${lngPercent}%; top: ${latPercent}%; width:${radius}%; padding:${radius}%;
              display:${pointerShow()[key] ? "block" : "none"}`}
            ></div> */}
            {pointerShow()[key] && (
              <>
                <img
                  src={"./images/Map/spot.svg"}
                  alt=""
                  key={key + "_spot"}
                  class="absolute center-xy w-20 pointer-events-none"
                  style={`left: ${map_xPercent}%; top: ${map_yPercent}%;}`}
                />
                <>
                  {targets[key].posName == undefined ? (
                    <img
                      src="./images/Map/activeRoute.svg"
                      alt=""
                      key={key + "_spot"}
                      class="absolute center-xy w-20 pointer-events-none"
                      style={`left: ${map_xPercent}%; top: ${map_yPercent}%; visibility:${
                        key === currentKey() ? "visible" : "hidden"
                      }`}
                    />
                  ) : (
                    <img
                      src="./images/Map/activeSpot.svg"
                      alt=""
                      key={key + "_spot"}
                      class="absolute center-xy w-20 pointer-events-none"
                      style={`left: ${map_xPercent}%; top: ${map_yPercent}%; visibility:${
                        key == currentKey() ? "visible" : "hidden"
                      }`}
                    />
                  )}
                </>
              </>
            )}
            <>
              {key == currentKey() && (
                <>
                  {targets[key].posName == undefined ? (
                    <p
                      class="absolute center-xy bg-white rounded-full border-[0.25rem] border-[#f15a24]  fontSizeNormal-[28] font-extrabold text-black min-w-96 text-center py-1 whitespace-normal map-text-route pointer-events-none z-10"
                      style={`left: ${map_xPercent}%; top: ${
                        map_yPercent - 6
                      }%;`}
                      innerHTML={targets[key].nestName}
                    ></p>
                  ) : (
                    <p
                      class="absolute center-xy bg-[#00c343] border-[0.25rem] border-white  fontSizeNormal-[28] font-extrabold text-white min-w-60 text-center py-1 whitespace-normal map-text-info px-6 pointer-events-none z-10"
                      style={`left: ${map_xPercent}%; top: ${
                        map_yPercent - 5
                      }%;`}
                    >
                      {targets[key].posName}
                    </p>
                  )}
                </>
              )}
            </>
          </>
        );
      }
    }
    return obj;
  }

  // 一番近いのスポット
  function witchPosNear(lng, lat) {
    let minKey = undefined;
    let minDistance = Infinity;

    for (const key in targets) {
      if (targets.hasOwnProperty(key)) {
        if (!targets[key].show) continue;

        if (!targets[key].isPassed) {
          const targetNum = ListVisibility.indexOf(key) - 1;
          if (0 <= targetNum) {
            if (!targets[ListVisibility[targetNum]].isPassed) {
              continue;
            }
          }
        }

        const location = targets[key].location;
        if (!Array.isArray(location)) continue;

        const dis = distanceGPS(lat, lng, location[0], location[1]) * 1000;
        if (dis < minDistance) {
          minDistance = dis;
          minKey = key;
        }
      }
    }

    setCurrentKey(minKey);
    setCurrentPos(targets[minKey]);

    if (currentPos().isPassed) return;

    if (minDistance < targets[minKey]?.radius) {
      if (!targetLoc[minKey].show) return;
      const isNext =
        targetLoc[minKey].posName != undefined &&
        targetLoc[minKey].nestName != undefined;
      setSoundGo(isNext, targetLoc[minKey].posName != undefined, true);
    }
  }

  // 自身の位置移動アニメーション
  function startPointerTween() {
    if (elem_pointer) {
      tween_goPointer = new TWEEN.Tween(lastPos, false)
        .to({ x: myPointer().x, y: myPointer().y }, 1000)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(function () {
          elem_pointer.style.left = lastPos.x + "%";
          elem_pointer.style.bottom = lastPos.y + "%";
        })
        .start();
    }
  }

  // 通過した数によるイベント
  function countPassedTargets() {
    let count = 0;
    for (const key in targets) {
      if (targets.hasOwnProperty(key)) {
        if (targets[key].isPassed) count++;
      }
    }
    return count;
  }

  // UI変更
  function updateUIState(count) {
    if (count == Object.keys(targets).length - 1) {
      updatePointerShow("museum", true);
      targets["museum"].show = true;
    }
  }

  // complete表示
  function showComplete(count) {
    if (
      count == Object.keys(targets).length &&
      !stat.pageState.popups.Music.value &&
      !isComplete()
    ) {
      setIsComplete(true);
      stat.pageState.popups.Complete.value = true;
    }
  }

  createEffect(() => {
    startPointerTween();

    setIsShowSpot(currentPos()?.posName == undefined);
    setIsShowRoute(currentPos()?.nestName == undefined);

    showComplete(countPassedTargets());
  });

  return (
    <MotionBase>
      <div class="absoluteCenter show h-full w-full flexCenter">
        <div class="h-full w-full flexCenter fitScreenY">
          <div class="h-full w-full eGrid-row-[1108,772,336] ">
            <div
              class="w-full h-full flex overflow-scroll hidden-scrollbar items-start justify-center"
              ref={elem_map}
            >
              <AspectFitter w={842} h={1199}>
                <div class="w-full h-full">
                  <div
                    class={`w-[200%] h-[200%] pointer-events-auto bg-cover bg-center relative bg-[url('/images/Map/map.svg')] overflow-hidden`}
                  >
                    <div
                      ref={elem_pointer}
                      class="w-per-[30] absolute centerLate pointer-events-none"
                    >
                      <img
                        src="./images/Map/pointer.svg"
                        alt=""
                        class="animate-spin trans w-full"
                      />
                    </div>
                    {setPointer()}
                  </div>
                </div>
              </AspectFitter>
            </div>
            <div class="w-full h-full eGrid-col-[750,546,204]">
              <div class="w-full h-full flexCenter">
                <div class="w-per-[488,546] h-4/5 flex flex-col justify-evenly items-center">
                  <div class="w-full">
                    <AspectFitter w={488} h={96}>
                      <button
                        class={`btn3D w-full h-full flex justify-start items-center ${
                          isShowSpot() ? "btn-dead" : "btn-currentSpot"
                        }`}
                        onclick={(ev) => {
                          removeClass(ev.target, "pointer-events-auto");
                          setSoundGo(false, true);
                          addClass(ev.target, "pointer-events-auto");
                        }}
                        id="spot_info"
                      >
                        <p class="fontSizeNormal-[30] font-noto font-black mt-2 pointer-events-none">
                          <img
                            class="inline-block align-middle w-14 mr-6 mb-1 ml-6"
                            src="./images/Map/spotInfo.svg"
                            style={{
                              display: isShowSpot() ? "none" : "inline-block",
                            }}
                            alt=""
                          />
                          <img
                            class="inline-block align-middle w-14 mr-6 mb-1 ml-6"
                            src="./images/Map/noTab.svg"
                            style={{
                              display: isShowSpot() ? "inline-block" : "none",
                            }}
                            alt=""
                          />
                          このスポットの解説
                        </p>
                      </button>
                    </AspectFitter>
                  </div>
                  <div class="w-full">
                    <AspectFitter w={488} h={96}>
                      <button
                        class={`text-black btn3D w-full h-full flex justify-start items-center ${
                          isShowRoute() ? "btn-dead" : "btn-nextSpot"
                        }`}
                        onclick={(ev) => {
                          removeClass(ev.target, "pointer-events-auto");
                          setSoundGo(false, false);
                          addClass(ev.target, "pointer-events-auto");
                        }}
                        id="next_route"
                      >
                        <p class="fontSizeNormal-[30] font-noto font-black mt-1 pointer-events-none">
                          <img
                            class="inline-block align-middle w-14 mr-6 mb-1 ml-6"
                            src="./images/Map/nexInfo.svg"
                            style={{
                              display: isShowRoute() ? "none" : "inline-block",
                            }}
                            alt=""
                          />
                          <img
                            class="inline-block align-middle w-14 mr-6 mb-1 ml-6"
                            src="./images/Map/noTab.svg"
                            style={{
                              display: isShowRoute() ? "inline-block" : "none",
                            }}
                            alt=""
                          />
                          次のスポットへの道案内
                        </p>
                      </button>
                    </AspectFitter>
                  </div>
                </div>
              </div>
              <div class="w-full h-full flexCenter">
                <div class="w-4/5 h-4/5 flexCenter">
                  <div class="w-full">
                    <AspectFitter w={170} h={221}>
                      <button
                        class="btn3DSub w-full h-full fontSizeNormal-[34] flexCenter flex-col"
                        style={{
                          "background-color": isFollowGPS()
                            ? "#ff1f39"
                            : "#a3a3a3",
                          "box-shadow": isFollowGPS()
                            ? "0rem 0.3rem #780000"
                            : "0rem 0.3rem #6b6b6b",
                        }}
                        onclick={(ev) => {
                          removeClass(ev.target, "pointer-events-auto");
                          if (!isFollowGPS()) followGPS();
                          setIsFollowGPS(!isFollowGPS());
                          addClass(ev.target, "pointer-events-auto");
                        }}
                      >
                        <img
                          class="align-middle w-16 mr-6 mb-1 ml-6"
                          src="./images/Map/nowPos.svg"
                          alt=""
                        />
                        <p class="text-white font-noto font-black pointer-events-none">
                          現在地
                        </p>
                        <p class="text-white font-noto font-black pointer-events-none">
                          {() => {
                            return isFollowGPS() ? "表示中" : "表示";
                          }}
                        </p>
                      </button>
                    </AspectFitter>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MotionBase>
  );
}

export default Map;
