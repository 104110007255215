import { createEffect, createSignal, onCleanup, onMount, Show } from "solid-js";
import MotionBase from "../../lib/MotionBase";
import BlackPanel from "../../lib/BlackPanel";
import { isSP } from "../../common/common";
import { removeClass } from "../../common/common";
import AspectFitter from "../../lib/AspectFitter";
import { end_sound, killAllSounds } from "../../lib/Howler";

import "./inputRange.css";
import myConfig from "../../pageConfig";
import { error, success, warning } from "../../common/log";
import { reloadWithParams } from "../../common/extra";
const Music = ({ ...stat }) => {
  /**
   * @type {Howl|null} currentMusic - Howler.jsのHowlオブジェクト
   */
  let currentMusic = null;
  let currentProperty = stat.state.currentTarget.value;
  let imgPath = myConfig.mapInfo.path_soundImg;
  let elem_seekBar = undefined;

  let isAutoPlay = true;
  let soundTime = 0;

  let isWait = false;

  const [ps, setPs] = createSignal(0); // シークバーを動かす
  const [isLoad, setIsLoad] = createSignal(true); // ロード中かどうか
  const [isPlayBtn, setIsPlayBtn] = createSignal(false); // ボタンの状態

  let nextTimeout = null;
  let errorLoadTimeOut = null;
  let updateInterval;

  let isBlock = false;

  //#region 再生ボタンのデザイン
  const playSymbol = () => {
    return (
      <>
        <rect x="4" y="3" width="4" height="14" />
        <rect x="12" y="3" width="4" height="14" />
      </>
    );
  };
  const pauseSymbol = () => {
    return <polygon points="5,3 19,10 5,17" />;
  };
  //#endregion

  // #region シークバー操作
  function handleSeekBarStart() {
    if (soundTime == 0) return;
    currentMusic.pause();
  }
  function handleSeekBarChange() {
    if (soundTime == 0) return;
    setPs(elem_seekBar.value * 100);
  }
  function handleSeekBarEnd() {
    if (soundTime == 0) return;
    const seekTime = parseFloat((soundTime * elem_seekBar.value).toFixed(2));
    currentMusic.seek(seekTime);
    if (isAutoPlay) oncePlay(currentMusic);
  }
  function handleSeekClick() {
    if (soundTime == 0) return;
    const seekTime = parseFloat((soundTime * elem_seekBar.value).toFixed(2));
    currentMusic.seek(seekTime);
    if (isAutoPlay) oncePlay(currentMusic);
  }
  // イベント登録
  function initSeekBar() {
    endInitSeekBar();
    // 長押し
    elem_seekBar.addEventListener("input", handleSeekBarChange);
    if (isSP()) {
      elem_seekBar.addEventListener("click", handleSeekClick);
      // 押したとき
      elem_seekBar.addEventListener("touchstart", handleSeekBarStart);
      // 離したとき
      elem_seekBar.addEventListener("touchend", handleSeekBarEnd);
    } else {
      // 押したとき
      elem_seekBar.addEventListener("mousedown", handleSeekBarStart);
      // 離したとき
      elem_seekBar.addEventListener("mouseup", handleSeekBarEnd);
    }
  }
  // イベント解除
  function endInitSeekBar() {
    // 長押し
    elem_seekBar.removeEventListener("input", handleSeekBarChange);
    if (isSP()) {
      elem_seekBar.removeEventListener("click", handleSeekClick);
      // 押したとき
      elem_seekBar.removeEventListener("touchstart", handleSeekBarStart);
      // 離したとき
      elem_seekBar.removeEventListener("touchend", handleSeekBarEnd);
    } else {
      // 押したとき
      elem_seekBar.removeEventListener("mousedown", handleSeekBarStart);
      // 離したとき
      elem_seekBar.removeEventListener("mouseup", handleSeekBarEnd);
    }
  }
  // #endregion

  // #region update
  // 情報の更新
  function startUpdateLoop() {
    // success("インターバルスタート");
    updateInterval = setInterval(() => {
      if (stat.pageState.popups.Music.value) {
        const progress = currentMusic.seek() / currentMusic.duration();
        elem_seekBar.value = progress;
        setPs(progress * 100);
      } else {
        stopUpdateLoop();
      }
    }, 100); // 1秒ごとに実行
  }
  // ループを停止する
  const stopUpdateLoop = () => {
    // warning("インターバルストップ");
    clearInterval(updateInterval);
  };

  //#endregion

  // 画面を閉じた時の挙動
  function closeWin() {
    if (document.hidden) {
      currentMusic.stop(); // 音声を停止
      isAutoPlay = false;
      setIsPlayBtn(false);
      reloadWithParams("jump", "Map");
    }
  }

  // 音声切り替え
  function changePlay() {
    if (isLoad()) return;
    if (currentMusic.playing()) {
      currentMusic.pause();
      isAutoPlay = false;
      setIsPlayBtn(false);
    } else {
      isAutoPlay = true;
      oncePlay(currentMusic);
    }
  }

  // 終了時のいベント
  function musicEndEvent() {
    setIsPlayBtn(false);
    isAutoPlay = false;
    // 次の音声がある場合
    if (stat.state.isNextMusic.value) {
      stat.state.isNextMusic.value = false;
      stat.state.isSpotMusic.value = false;
      const tmp = currentMusic;
      end_sound(tmp);
      initEndMusic(tmp);
      currentMusic = null;
      setNextSound();
    } else {
      // スポットに入った時の音声の場合自動的にOFFにする
      if (stat.state.isInSpot.value) stat.pageState.popups.Music.value = false;
    }
  }

  // 次のタイムアウトの実行キャンセル
  const stopNextTimeOut = () => {
    clearTimeout(nextTimeout);
  };

  // 次の音生成
  const setNextSound = async () => {
    try {
      setIsPlayBtn(false);
      elem_seekBar.value = 0;
      setPs(0);
      isAutoPlay = false;
      currentMusic = await makeSound();
      errorMusic(currentMusic);
      initMusic(currentMusic);
      isWait = true;
      // 再生待ち
      nextTimeout = setTimeout(() => {
        isWait = false;
        if (currentMusic == null) return null;
        if (currentMusic.seek() == 0 && currentMusic.state() === "loaded") {
          isAutoPlay = true;
          oncePlay(currentMusic);
        }
      }, myConfig.howlerInfo.nextWait);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // 音の生成
  function makeSound() {
    const tail = stat.state.isSpotMusic.value ? "_info" : "_route";
    const name = stat.state.currentKey.value;
    const soundKey = name + tail; // 音声のキーを作成

    // リストにあるかどうか
    if (stat.state.sounds.value.includes(soundKey)) {
      // 既に生成されているかどうか;
      if (soundKey in stat.state.soundsList.value) {
        const newSound = stat.state.soundsList.value[soundKey];
        if (isAutoPlay && !newSound.playing()) {
          newSound.seek(0);
          setIsPlayBtn(true);
          newSound.play();
        }
        return newSound;
      }
      // error("サウンドがなかったため、生成します");
      return setSound(soundKey);
    }
    stat.pageState.popups.Music.value = false;
    return null;
  }
  // 音を登録
  function setSound(soundKey) {
    const key = myConfig.howlerInfo.mainPath + soundKey + ".mp3";
    const newSound = new Howl({
      src: [key],
      html5: false,
      pool: 1,
      onload: function () {
        // console.warn(`Loaded: ${myConfig.howlerInfo.testSound}`);
        setIsLoad(false);
        if (!newSound.playing() && !isWait && !isBlock) {
          newSound.play();
          newSound.seek(0);
          setIsPlayBtn(true);
        }
      },
      onloaderror: function (id, error) {
        // エラー時再ロード
        errorLoadTimeOut = setTimeout(() => {
          setSound(soundKey);
        }, 1000);
      },
    });
    stat.state.soundsList.value = {
      ...stat.state.soundsList.value,
      [soundKey]: newSound, // 音声を追加
    };
    return newSound;
  }

  // 次のタイムアウトの実行キャンセル
  const stopErrorLoadTimeOut = () => {
    clearTimeout(errorLoadTimeOut);
  };

  // 音の設定
  function initMusic(sound) {
    if (sound.state() === "loaded") {
      // ロードされている場合
      afterLoadInit(sound);
      setIsLoad(false);
    } else {
      // ロード待ちの場合;
      setIsLoad(true);
      sound.once("load", () => {
        afterLoadInit(sound);
        setIsLoad(false);
      });
    }
  }

  // ロード後に設定
  function afterLoadInit(sound) {
    sound.on("end", musicEndEvent);
    sound.volume(myConfig.howlerInfo.MAX_VOLUME);
    sound.seek(0);
    initSeekBar();
    soundTime = sound.duration();
  }
  // 設定OFF
  function initEndMusic(sound) {
    sound.off("end", musicEndEvent);
    sound.stop();
    setIsPlayBtn(false);
    endInitSeekBar();
  }

  onMount(async () => {
    try {
      currentMusic = await makeSound(); // makeSoundの処理が終わるまで待つ
      // ウィンドウが閉じる前の処理
      window.addEventListener("visibilitychange", closeWin);
      errorMusic(currentMusic);
      initMusic(currentMusic);
    } catch (error) {
      console.error("Error in onMount:", error);
    }
  });

  onCleanup(() => {
    isBlock = true;
    // ウィンドウが閉じる前の処理
    window.removeEventListener("visibilitychange", closeWin);
    if (currentMusic != null) {
      stopUpdateLoop();
      currentMusic.off("end", musicEndEvent);
      errorMusicEnd(currentMusic);
      endInitSeekBar();
      killAllSounds();
    }
    stopNextTimeOut();
    stopErrorLoadTimeOut();
  });

  function resetMusic() {
    const tail = stat.state.isSpotMusic.value ? "_info" : "_route";
    const name = stat.state.currentKey.value;
    const soundKey = name + tail; // 音声のキーを作成
    currentMusic = setSound(soundKey); // makeSoundの処理が終わるまで待つ
    errorMusic(currentMusic);
    initMusic(currentMusic);
    oncePlay(currentMusic);
  }
  createEffect(() => {});

  // プレイエラー
  function playError(id, error) {
    // console.error(`Play Error ID: ${id}, Error Code: ${error}`);
    stopUpdateLoop();
    resetMusic();
  }

  // 関数設定
  function errorMusic(sound) {
    errorMusicEnd(sound);
    sound.on("playerror", playError);
    sound.on("play", startUpdateLoop);
    sound.on("end", stopUpdateLoop);
    sound.on("pause", stopUpdateLoop);
  }
  // 関数設定
  function errorMusicEnd(sound) {
    sound.off("playerror", playError);
    sound.off("play", startUpdateLoop);
    sound.off("end", stopUpdateLoop);
    sound.off("pause", stopUpdateLoop);
  }
  function oncePlay(music) {
    if (!music.playing()) {
      music.play();
      setIsPlayBtn(true);
    }
  }

  return (
    <MotionBase>
      <BlackPanel>
        <div class="h-full w-full flex justify-center items-center">
          <div class="w-full h-full eGrid-row-[1108,932,176] fitScreenY">
            <div class="w-full h-full flex justify-center items-center">
              <div class="term w-per-[654] rounded-sm flexCenter">
                <AspectFitter w={654} h={886}>
                  <div
                    class="w-full h-full eGrid-row-[886,570,316] bg-white rounded-3xl bg-no-repeat bg-cover"
                    style={{
                      "background-image": stat.state.isSpotMusic.value
                        ? "./images/Map/bg_info.svg"
                        : "./images/Map/bg_route.svg",
                    }}
                  >
                    <div class="w-full h-full flexCenter flex-col">
                      <div class="w-per-[556,654] h-full font-black flex flex-col justify-center items-start">
                        <p class="font-noto font-extrabold fontSizeNormal-[28] tracking-wider text-black mt-10 mb-2">
                          {stat.state.isSpotMusic.value
                            ? "このスポットの解説"
                            : currentProperty.posName == undefined
                            ? "道案内"
                            : "次のスポットへの道案内"}
                        </p>
                        <p
                          class="font-noto font-black tracking-widest text-customBase whitespace-normal leading-10"
                          innerHTML={
                            stat.state.isSpotMusic.value
                              ? currentProperty.posName
                              : currentProperty.nestName
                          }
                          style={{
                            "font-size": currentProperty.fontSize + "rem",
                            color: stat.state.isSpotMusic.value
                              ? "#39B54A"
                              : "#F15A24",
                          }}
                        ></p>
                        <div class="w-full mb-auto mt-9">
                          <AspectFitter w={556} h={343}>
                            <img
                              class="w-full"
                              src={
                                +stat.state.isSpotMusic.value
                                  ? imgPath + currentProperty.posImg + ".png"
                                  : imgPath + currentProperty.nextImg + ".png"
                              }
                              alt=""
                            />
                            <Show when={isLoad()}>
                              <div class="absolute w-full h-full center-xy bg-black opacity-80 rounded-2xl flexCenter">
                                <div role="status" class="">
                                  <svg
                                    aria-hidden="true"
                                    class="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            </Show>
                          </AspectFitter>
                        </div>
                      </div>
                      <div class="border-dashed border-2 border-customBase w-full"></div>
                    </div>
                    <div class="flexCenter">
                      <div class="w-full h-4/5 flex items-center justify-around flex-col">
                        <div class="w-per-[250,300]">
                          <input
                            type="range"
                            value="0"
                            min="0"
                            max="1"
                            step="0.01"
                            ref={elem_seekBar}
                            disabled={isLoad()}
                            class="range1"
                            style={{ "--ps": `${ps()}%` }} // CSS変数に動的に値を適用
                            id="seekBar"
                          ></input>
                        </div>
                        <div class="flex justify-around items-center w-per-[250,300]">
                          <button
                            class="bg-red-600 rounded-full p-7 flex items-center justify-center btn"
                            style={{
                              "background-color": isPlayBtn()
                                ? "#8b8b8b"
                                : "#ff5802",
                            }}
                            onClick={changePlay}
                          >
                            <svg
                              class="w-16 h-16 text-white"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <Show when={isPlayBtn()} fallback={pauseSymbol()}>
                                {playSymbol()}
                              </Show>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </AspectFitter>
              </div>
            </div>
            <div class="w-full h-full flexCenter">
              <div class="w-per-[280] h-auto">
                <AspectFitter w={280} h={103}>
                  <div class="w-full h-full flex justify-end items-center">
                    <button
                      type="button"
                      class="btn bg-white rounded-full border-customBase border-[0.5rem] w-full h-full flex justify-center items-center"
                      onClick={(ev) => {
                        removeClass(ev.target, "pointer-events-auto");
                        stat.pageState.popups.Music.value = false;
                      }}
                    >
                      <div class="w-[70%] h-full flex justify-between items-center">
                        <div class=" bg-customBase rounded-full w-min p-1">
                          <svg
                            class="h-12 w-12 text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            {" "}
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                          </svg>
                        </div>
                        <p class=" text-black  fontSizeNormal-[38] tracking-widest font-black">
                          閉じる
                        </p>
                      </div>
                    </button>
                  </div>
                </AspectFitter>
              </div>
            </div>
          </div>
        </div>
      </BlackPanel>
    </MotionBase>
  );
};
export default Music;
