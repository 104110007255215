// Howler.js の Howl クラスをインポート
import { Howl, Howler } from "howler";

// 音声ファイルのパス
const MS_TIME_FADEOUT = 10;
const MAX_VOLUME = 1.0;
const maxRetries = 3; // 最大再試行回数

/**
 * サウンドがロードされているか確認し、ロードされていない場合はロードを待つ関数
 * @param {Howl} sound - Howlインスタンス
 * @returns {Promise<boolean>} サウンドが正常にロードされ、durationが有効かどうかを示すPromise
 */
async function check_load(sound) {
  if (sound.state() !== "loaded") {
    await new Promise((resolve, reject) => {
      sound.once("load", resolve);
      sound.once("loaderror", reject);
      sound.load();
    });
  }
  const duration = sound.duration();
  if (duration <= 0) {
    console.error("Failed to get sound duration.");
    return false;
  } else {
    return true;
  }
}

/**
 * 音声ファイルを生成する関数
 * @returns {Howl[]} Howlインスタンスの配列
 */
export function make_sound(name, path) {
  // let retryCount = 0; // 再試行回数
  // 各音声ファイルのパスを生成
  const src = path + name + ".mp3";
  // Howl インスタンスを作成
  const howl = new Howl({
    src: [src],
    html5: false,
    preload: true, // プレロードを有効化
    buffer: true, // バッファリングを有効化
    pool: 1,
  });

  return howl;
}

/**
 * 複数の音声ファイルを生成する関数
 * @returns {Howl[]} Howlインスタンスのオブジェクト
 */
export function make_sounds(lists_sounds, path) {
  let sounds = {};

  for (const element of lists_sounds) {
    // 各音声ファイルのパスを生成
    const src = path + element + ".mp3";

    // Howl インスタンスを作成
    const howl = new Howl({
      src: [src],
      html5: true,
      preload: true, // プレロードを有効化
      buffer: true, // バッファリングを有効化
      pool: 1,
      onload: () => {
        console.log(`${src}がロード完了しました`);
        howl.loading = false;
      },
      onloaderror: (id, err) => {
        console.error(`${src}のロードに失敗しました: ${err}`);
      },
      onplayerror: function (id, err) {
        console.error("再生中にエラーが発生しました: ", err);
      },
    });
    howl.loading = true;
    // `load`イベントを使用して、音声の状態を確認
    howl.on("load", () => {
      if (howl.state() === "loaded") {
        console.log(`${src}は完全にロードされました`);
      } else {
        console.error(`${src}はまだ完全にロードされていません`);
      }
    });

    // sounds 配列に追加
    sounds[element] = howl;
  }

  return sounds; // soundsはオブジェクトです
}

/**
 * サウンドのシーク位置を変更する関数
 * @param {Howl} sound - Howlインスタンス
 * @param {number} percent - シーク位置をパーセンテージで指定
 */
export async function change_seek(sound, percent) {
  if (await check_load(sound)) {
    const duration = sound.duration();
    sound.seek(percent * 0.01 * duration);
  }
}

/**
 * サウンドを最初から再生する関数
 * @param {Howl} sound - Howlインスタンス
 */
export async function change_firstStart(sound) {
  if (await check_load(sound)) {
    sound.volume(MAX_VOLUME);
    sound.seek(0);
    // sound.play();
  }
}

/**
 * サウンドをフェードアウトして停止する関数
 * @param {Howl} sound - Howlインスタンス
 */
export async function end_sound(sound) {
  if (await check_load(sound)) {
    sound.fade(1, 0, MS_TIME_FADEOUT);
    setTimeout(() => {
      sound.stop();
      // sound.unload();
    }, MS_TIME_FADEOUT);
  }
}

/**
 * すべての音声を停止する関数
 * @param {Object} sounds - Howlインスタンスのオブジェクト
 */
export function stopAllSounds(sounds) {
  Object.values(sounds).forEach((howl) => {
    if (howl.playing()) {
      // 音声が再生中であるか確認
      howl.stop(); // 音声を停止
      // console.log(`音声を停止しました: ${howl._src}`); // 停止した音声のログ
    } else {
      // console.log(`音声は再生されていません: ${howl._src}`); // 再生されていない場合のログ
    }
  });
}

export function initHowler() {
  // Howlerのグローバル設定を変更
  Howler.autoUnlock = true; // 自動的にロック解除を有効化
  Howler.poolSize = 20; // オーディオプールのサイズを10に設定
  // console.log("init");
}

/**
 * すべての音声を停止する関数
 * @param {Object} sounds - Howlインスタンスのオブジェクト
 */
export function killAllSounds() {
  Howler.stop();
}
