import SignalProperty from "./SignalProperty";
import { pages, modals, BEGIN_PAGE } from "../pageSet";

const pageConfig = () => {
  const config = {
    pages: pages,
    popups: modals,
    pageState: {
      isPortrait: new SignalProperty(true),
      currentPage: new SignalProperty(BEGIN_PAGE),
      popups: popupProperty(modals),
      pages: pageProperty(pages),
    },
    changePage: function (to) {
      this.pageState.currentPage.value = to;
    },
    hasPage: function (name) {
      return Object.hasOwnProperty(this.pages, name); // pages を this.pages に修正
    },
  };
  return config;
};

/**
 *  ポップアップ表示フラグ(関数名を変数名として登録)
 * @returns {Array<SignalProperty<boolean>>}
 * */
function popupProperty(popups) {
  const work = {};
  for (const key of Object.keys(popups)) {
    work[key] = new SignalProperty(false);
  }
  return work;
}
/**
 *  ページ遷移フラグ
 * @returns {Array<SignalProperty<boolean>>}
 * */
function pageProperty(pages) {
  const work = {};
  for (const key of Object.keys(pages)) {
    work[key] = new SignalProperty(false);
  }
  return work;
}

export default pageConfig;
