// ページ設定
const myConfig = {
  AISize: {
    portrait: {
      header: 70,
      footer: 45,
      w: 750,
      h: 1108,
    },
  },

  DebugMode: true,
  // DebugInitPage: "Map",
  DebugInitPage: "Title",

  modal_Z: 100,
  modalOver_Z: 1000,
  modalColor: "rgba(0,0,0,0.5)",

  mainScroll: {
    thumbColor: "#00c343",
    trackColor: "#bbbbbb",
  },

  defaultBtnSetting: {
    bgColor: "#929292",
    borderColor: "black",
    borderW: 1,
  },

  mainBtnSet: {
    bgColor: "#ffffff",
    borderColor: "#00c343",
    borderW: 5,
  },

  mapBtnInfo: {
    bgColor: "#00c343",
    borderColor: "#00c343",
  },
  mapBtnRoad: {
    bgColor: "#ffffff",
    borderColor: "#ff5802",
    borderW: 5,
  },

  scrollBar: {
    bg: "#00c343",
    border: "#00c343",
    trackBg: "#00c343",
  },
  howlerInfo: {
    mainPath: "./sounds/",
    testSound: "test",
    MAX_VOLUME: 0.5,
    nextWait: 5000,
  },
  mapInfo: {
    path_file: "./images/Map/",
    path_soundImg: "./images/Map/music/",
  },
};

// 共通項目
const commonState = {
  location: [0, 0],
  mapPos: [0, 0],
  posName: undefined,
  nestName: undefined,
  posImg: undefined,
  nextImg: undefined,
  radius: 20,
  fontSize: 2.5,
  show: true,
  isPassed: false,
  numOpen: undefined,
};

export const targetLoc = {
  tanaka: {
    ...commonState,
    location: [35.78540805788513, 139.64288842395845],
    mapPos: [98.7318, 174.2643],
    posName: "旧田中家住宅",
    nestName: "板橋区立美術館",
    posImg: "tanaka",
    nextImg: "artMuseum",
    radius: 15,
    show: true,
  },
  artMuseum: {
    ...commonState,
    location: [35.78476715893044, 139.64436699843313],
    mapPos: [528.1859, 559.9747],
    posName: "板橋区立美術館",
    nestName: "赤塚不動の滝",
    posImg: "artMuseum",
    nextImg: "waterfall",
    radius: 10,
    show: true,
  },
  waterfall: {
    ...commonState,
    location: [35.78263191935573, 139.64526574127808],
    mapPos: [728.7196, 1011.0225],
    posName: "赤塚不動の滝",
    nestName: "城址の梅林",
    posImg: "waterfall",
    nextImg: "plumGrove",
    radius: 15,
    show: true,
    numOpen: "route01",
  },
  route01: {
    ...commonState,
    location: [35.78309125109377, 139.64492087713631],
    mapPos: [582.4545, 821.9556],
    posName: undefined,
    nestName: "美術館入口交差点を<br />左折する",
    fontSize: 2,
    posImg: undefined,
    nextImg: "route01",
    radius: 13,
    show: false,
  },
  route02: {
    ...commonState,
    location: [35.78286890805529, 139.64357302316375],
    mapPos: [285.4332, 945.4933],
    posName: undefined,
    nestName: "折り返しの形で <br />右手前にある坂道を登る",
    fontSize: 2,
    posImg: undefined,
    nextImg: "route02",
    radius: 14,
    show: true,
    isPassed: false,
  },
  route03: {
    ...commonState,
    location: [35.78351302916488, 139.64373749770084],
    mapPos: [326.9029, 745.893],
    posName: undefined,
    nestName: "赤塚公園への<br />入り口を右折する",
    fontSize: 2,
    posImg: undefined,
    nextImg: "route03",
    radius: 15,
    show: true,
  },
  plumGrove: {
    ...commonState,
    location: [35.783982967029594, 139.6437795532573],
    mapPos: [324.6595, 586.736],
    posName: "城址の梅林",
    nestName: "赤塚城址",
    posImg: "plumGrove",
    nextImg: "castleRuins",
    radius: 15,
    show: true,
  },
  castleRuins: {
    ...commonState,
    location: [35.78447906639664, 139.6437607777942],
    mapPos: [286.6803, 434.8974],
    posName: "赤塚城址",
    nestName: "板橋区立郷土資料館",
    posImg: "castleRuins",
    nextImg: "museum",
    radius: 15,
    show: true,
  },
  route04: {
    ...commonState,
    location: [35.78475275184331, 139.64361803995828],
    mapPos: [279.3302, 346.6482],
    posName: undefined,
    nestName: "ふたつの道のうち<br />奥側の道を右折する",
    fontSize: 2,
    posImg: undefined,
    nextImg: "route04",
    radius: 10,
    show: true,
  },
  museum: {
    ...commonState,
    location: [35.78532337774101, 139.6435621208536],
    mapPos: [277.1301, 209.117],
    posName: "板橋区立郷土資料館",
    posImg: "museum",
    nextImg: undefined,
    nextPos: undefined,
    radius: 10,
    show: false,
  },
};

export const MapPos = {
  r_u: [35.786094305307486, 139.64568537354225],
  l_u: [35.786094305307486, 139.64238201860243],
  r_d: [35.7821533059001, 139.64568537354225],
  l_d: [35.7821533059001, 139.64238201860243],
};

export default myConfig;
