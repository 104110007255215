import { createEffect, createSignal, onCleanup, onMount } from "solid-js";
import MotionBase from "../../lib/MotionBase";
import BlackPanel from "../../lib/BlackPanel";
import { isSP } from "../../common/common";
import { removeClass } from "../../common/common";
import AspectFitter from "../../lib/AspectFitter";
import { end_sound, killAllSounds } from "../../lib/Howler";
import "./inputRange.css";
import myConfig from "../../pageConfig";
import { success, warning } from "../../common/log";
import { reloadWithParams } from "../../common/extra";
// import "../../../css/modal.css";

const TestPop = ({ ...stat }) => {
  /**
   * @type {Howl|null} currentMusic - Howler.jsのHowlオブジェクト
   */
  let currentMusic = null;
  let elem_seekBar = undefined;

  let isAutoPlay = true;
  let soundTime = 0;
  let errorLoadTimeOut = null;

  const [ps, setPs] = createSignal(0); // シークバーを動かす
  const [isLoad, setIsLoad] = createSignal(true); // ロード中かどうか
  const [isPlayBtn, setIsPlayBtn] = createSignal(true); // ボタンの状態

  //#region 再生ボタンのデザイン
  const playSymbol = () => {
    return (
      <>
        <rect x="4" y="3" width="4" height="14" />
        <rect x="12" y="3" width="4" height="14" />
      </>
    );
  };
  const pauseSymbol = () => {
    return <polygon points="5,3 19,10 5,17" />;
  };
  //#endregion

  // #region シークバー操作
  function handleSeekBarStart() {
    if (soundTime == 0) return;
    currentMusic.pause();
  }
  function handleSeekBarChange(event) {
    if (soundTime == 0) return;
    setPs(event.target.value * 100);
  }
  function handleSeekBarEnd(event) {
    if (soundTime == 0) return;
    const seekTime = parseFloat((soundTime * event.target.value).toFixed(2));
    currentMusic.seek(seekTime);
    if (isAutoPlay && !currentMusic.playing()) currentMusic.play();
  }
  function handleSeekClick(event) {
    if (soundTime == 0) return;
    const seekTime = parseFloat((soundTime * event.target.value).toFixed(2));
    currentMusic.seek(seekTime);
    if (isAutoPlay && !currentMusic.playing()) currentMusic.play();
  }
  // イベント登録
  function initSeekBar() {
    endInitSeekBar();
    // 長押し
    elem_seekBar.addEventListener("input", handleSeekBarChange);
    if (isSP()) {
      elem_seekBar.addEventListener("click", handleSeekClick);
      // 押したとき
      elem_seekBar.addEventListener("touchstart", handleSeekBarStart);
      // 離したとき
      elem_seekBar.addEventListener("touchend", handleSeekBarEnd);
    } else {
      // 押したとき
      elem_seekBar.addEventListener("mousedown", handleSeekBarStart);
      // 離したとき
      elem_seekBar.addEventListener("mouseup", handleSeekBarEnd);
    }
  }
  // イベント解除
  function endInitSeekBar() {
    // 長押し
    elem_seekBar.removeEventListener("input", handleSeekBarChange);
    if (isSP()) {
      elem_seekBar.removeEventListener("click", handleSeekClick);
      // 押したとき
      elem_seekBar.removeEventListener("touchstart", handleSeekBarStart);
      // 離したとき
      elem_seekBar.removeEventListener("touchend", handleSeekBarEnd);
    } else {
      // 押したとき
      elem_seekBar.removeEventListener("mousedown", handleSeekBarStart);
      // 離したとき
      elem_seekBar.removeEventListener("mouseup", handleSeekBarEnd);
    }
  }
  // #endregion

  let updateInterval;
  // 情報の更新
  function startUpdateLoop() {
    // success("インターバルスタート");
    updateInterval = setInterval(() => {
      const progress = currentMusic.seek() / soundTime;
      elem_seekBar.value = progress;
      setPs(progress * 100);
    }, 100); // 1秒ごとに実行
  }
  // ループを停止する
  const stopUpdateLoop = () => {
    // warning("インターバルストップ");
    clearInterval(updateInterval);
  };

  // 画面を閉じた時の挙動
  function closeWin() {
    if (document.hidden) {
      currentMusic.stop(); // 音声を停止
      isAutoPlay = false;
      setIsPlayBtn(false);
      reloadWithParams("jump", "Test");
    }
  }

  // 音声切り替え
  function changePlay() {
    if (isLoad()) return;
    if (currentMusic.playing()) {
      currentMusic.pause();
      isAutoPlay = false;
      setIsPlayBtn(false);
    } else {
      currentMusic.play();
      isAutoPlay = true;
      setIsPlayBtn(true);
    }
  }

  // 終了時のいベント
  function musicEndEvent() {
    setIsPlayBtn(false);
    isAutoPlay = false;
  }

  // 音の設定
  function initMusic(sound) {
    if (sound.state() === "loaded") {
      // success("音声はロードされています。");
      afterLoadInit(sound);
      setIsLoad(false);
    } else {
      sound.once("load", () => {
        // success("音声はロードされています。");
        afterLoadInit(sound);
        setIsLoad(false);
      });
      // warning(`音声は現在 ${sound.state()} 状態です。`);
      setIsLoad(true);
    }
  }

  // ロード後に設定
  function afterLoadInit(sound) {
    sound.on("end", musicEndEvent);
    sound.volume(myConfig.howlerInfo.MAX_VOLUME);
    sound.seek(0);
    initSeekBar();
    sound.play();
    setIsPlayBtn(true);
    soundTime = sound.duration();
  }
  // 設定OFF
  function initEndMusic(sound) {
    sound.off("end", musicEndEvent);
    endInitSeekBar();
    sound.stop();
    setIsPlayBtn(false);
  }

  // 音の生成
  function makeSound() {
    const soundPath = myConfig.howlerInfo.mainPath;
    const name = myConfig.howlerInfo.testSound;
    const soundKey = soundPath + name + ".mp3";

    if (stat.state.testSound.value == undefined) {
      const newSound = new Howl({
        src: [soundKey],
        html5: false,
        pool: 1,
        onload: function () {
          // console.warn(`Loaded: ${myConfig.howlerInfo.testSound}`);
          setIsLoad(false);
          if (!newSound.playing()) {
            newSound.seek(0);
            newSound.play();
            setIsPlayBtn(true);
          }
        },
        onloaderror: function (id, error) {
          // エラー時再ロード
          errorLoadTimeOut = setTimeout(() => {
            makeSound();
          }, 1000);
        },
      });
      return newSound;
    }
    return stat.state.testSound.value;
  }

  onMount(async () => {
    try {
      currentMusic = await makeSound(); // makeSoundの処理が終わるまで待つ
      // ページを閉じた時
      window.addEventListener("visibilitychange", closeWin);
      errorMusic(currentMusic);
      initMusic(currentMusic);
    } catch (error) {
      // console.error("Error in makeSound:", error);
    }
  });

  onCleanup(() => {
    // ページを閉じた時
    window.addEventListener("visibilitychange", closeWin);
    if (currentMusic != null) {
      stopUpdateLoop();
      currentMusic.off("end", musicEndEvent);
      errorMusicEnd(currentMusic);
      initEndMusic(currentMusic);
      endInitSeekBar();
      killAllSounds();
    }
    clearTimeout(errorLoadTimeOut);
  });

  function setSound(path) {
    const newSound = new Howl({
      src: [path],
      html5: false,
      pool: 1,
      onload: function () {
        console.warn(`Loaded: ${myConfig.howlerInfo.testSound}`);
        setIsLoad(false);
      },
    });
    stat.state.testSound.value = newSound;
    return newSound;
  }

  function resetMusic() {
    const path =
      myConfig.howlerInfo.mainPath + myConfig.howlerInfo.testSound + ".mp3";
    currentMusic = setSound(path); // makeSoundの処理が終わるまで待つ
    errorMusic(currentMusic);
    initMusic(currentMusic);
  }
  createEffect(() => {
    // seekBar.disabled = isBuffer() || isLoad();
  });

  // プレイエラー
  function playError(id, error) {
    // console.error(`Play Error ID: ${id}, Error Code: ${error}`);
    stopUpdateLoop();
    resetMusic();
  }

  // エラー設定
  function errorMusic(sound) {
    errorMusicEnd(sound);
    sound.on("playerror", playError);
    sound.on("play", startUpdateLoop);
    sound.on("end", stopUpdateLoop);
    sound.on("pause", stopUpdateLoop);
  }
  // エラー設定
  function errorMusicEnd(sound) {
    sound.off("playerror", playError);
    sound.off("play", startUpdateLoop);
    sound.off("end", stopUpdateLoop);
    sound.off("pause", stopUpdateLoop);
  }

  return (
    <MotionBase>
      <BlackPanel>
        <div class="h-full w-full flex justify-center items-center">
          <div class="w-full h-full eGrid-row-[1108,932,176] fitScreenY">
            <div class="w-full h-full flex justify-center items-center">
              <div class="term w-per-[654] rounded-sm flexCenter">
                <AspectFitter w={654} h={886}>
                  <div class="w-full h-full eGrid-row-[886,570,316] bg-white rounded-3xl bg-no-repeat bg-cover relative">
                    <div class="w-full h-full flexCenter flex-col">
                      <div class="w-per-[541,654] h-full font-black">
                        <p class="font-noto font-black fontSizeNormal-[48] tracking-widest text-customBase my-16">
                          テスト音声再生
                          <img
                            class="inline-block align-middle w-14 ml-2 mb-2"
                            src="./images/testImg/speaker.svg"
                            alt=""
                          />
                        </p>
                        <p class="font-noto font-bold fontSizeNormal-[34] break-normal w-full">
                          <img
                            class="inline-block align-middle w-32 mr-2 mb-2"
                            src="./images/testImg/seekBar.svg"
                            alt=""
                          />
                          の緑の円を指で動かすと音声を進めたり戻したりできます。
                        </p>
                        <br />
                        <p class="font-noto font-bold fontSizeNormal-[34]">
                          <img
                            class="inline-block align-middle w-14 mr-2 mb-2"
                            src="./images/testImg/stop.svg"
                            alt=""
                          />
                          をタップすると <br /> 音声を一時停止します。
                        </p>
                      </div>
                      <div class="border-dashed border-2 border-customBase w-full"></div>
                    </div>
                    <div class="flexCenter">
                      <div class="w-full h-4/5 flex items-center justify-around flex-col">
                        <div class="w-per-[250,300]">
                          <input
                            type="range"
                            value="0"
                            min="0"
                            max="1"
                            step="0.01"
                            ref={elem_seekBar}
                            class="range1"
                            style={{ "--ps": `${ps()}%` }} // CSS変数に動的に値を適用
                            id="seekBar"
                          ></input>
                        </div>
                        <div class="flex justify-around items-center w-per-[250,300]">
                          <button
                            class="bg-red-600 rounded-full p-7 flex items-center justify-center btn"
                            style={{
                              "background-color": isPlayBtn()
                                ? "#8b8b8b"
                                : "#ff5802",
                            }}
                            onClick={changePlay}
                          >
                            <svg
                              class="w-16 h-16 text-white"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <Show when={isPlayBtn()} fallback={pauseSymbol()}>
                                {playSymbol()}
                              </Show>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <Show when={isLoad()}>
                      <div class="absolute w-full h-[100.1%] center-xy bg-black opacity-80 rounded-3xl flexCenter">
                        <div role="status" class="">
                          <svg
                            aria-hidden="true"
                            class="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </Show>
                  </div>
                </AspectFitter>
              </div>
            </div>
            <div class="w-full h-full flexCenter">
              <div class="w-per-[280] h-auto">
                <AspectFitter w={280} h={103}>
                  <div class="w-full h-full flex justify-end items-center">
                    <button
                      type="button"
                      class="btn bg-white rounded-full border-customBase border-[5px] w-full h-full flex justify-center items-center"
                      onClick={(ev) => {
                        removeClass(ev.target, "pointer-events-auto");
                        stat.pageState.popups.TestPop.value = false;
                      }}
                    >
                      <div class="w-[70%] h-full flex justify-between items-center">
                        <div class=" bg-customBase rounded-full w-min p-1">
                          <svg
                            class="h-12 w-12 text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            {" "}
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                          </svg>
                        </div>
                        <p class=" text-black  fontSizeNormal-[38] tracking-widest font-black">
                          閉じる
                        </p>
                      </div>
                    </button>
                  </div>
                </AspectFitter>
              </div>
            </div>
          </div>
        </div>
      </BlackPanel>
    </MotionBase>
  );
};
export default TestPop;
