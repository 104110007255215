/**
 * 現在のGPS位置情報を保持するオブジェクト
 * @typedef {Object} GPS
 * @property {number} lat - 緯度
 * @property {number} lng - 経度
 */
const GPS = { lat: 0, lng: 0 };
let navId = null;

// カスタムイベントを定義（detailには必要な情報のみを含める）
let gpsUpdateEvent = new CustomEvent("gpsUpdate", {
  detail: { lat: GPS.lat, lng: GPS.lng },
});

/**
 * 位置情報取得に失敗した場合のエラーハンドリング関数
 */
function error() {
  alert("位置情報を取得できませんでした。ページを更新してください");
  if (navId !== null) {
    navigator.geolocation.clearWatch(navId);
    navId = null;
  }
}

/**
 * 現在のGPS位置情報を取得する関数
 */
export function observeGPS() {
  if (navId === null) {
    navId = navigator.geolocation.watchPosition(
      (position) => {
        // 緯度と経度の取得
        const newLat = position.coords.latitude;
        const newLng = position.coords.longitude;

        GPS.lat = newLat;
        GPS.lng = newLng;

        // カスタムイベントのdetailを更新
        gpsUpdateEvent = new CustomEvent("gpsUpdate", {
          detail: { lat: GPS.lat, lng: GPS.lng },
        });
        // カスタムイベントをdocumentオブジェクトに発火
        document.dispatchEvent(gpsUpdateEvent);
        // 前回の座標と異なる場合にのみイベントを発火
        if (GPS.lat !== newLat || GPS.lng !== newLng) {
          GPS.lat = newLat;
          GPS.lng = newLng;
        }
      },
      error,
      {
        enableHighAccuracy: true,
        maximumAge: 0,
      }
    );
  }
}

/**
 * 現在のGPS位置情報を取得しPromiseで返す関数
 * @returns {Promise<GPS>} 現在のGPS位置情報
 */
export function getGPS() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        GPS.lat = position.coords.latitude;
        GPS.lng = position.coords.longitude;
        resolve(GPS);
      },
      reject,
      { enableHighAccuracy: true, maximumAge: 0 }
    );
  });
}

/**
 * GPS機能が利用可能かチェックする関数
 * @returns {boolean} GPS機能の可否
 */
export function isCheckUseGPS() {
  return "geolocation" in navigator;
}

/**
 * 2点間の距離を計算する関数
 * @param {number} lat1 - 点1の緯度
 * @param {number} lon1 - 点1の経度
 * @param {number} lat2 - 点2の緯度
 * @param {number} lon2 - 点2の経度
 * @returns {number} 2点間の距離（キロメートル）
 */
export function distanceGPS(lat1, lon1, lat2, lon2) {
  /**
   * 度をラジアンに変換する関数
   * @param {number} degrees - 度
   * @returns {number} ラジアン
   */
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  const lat1Rad = toRadians(lat1);
  const lon1Rad = toRadians(lon1);
  const lat2Rad = toRadians(lat2);
  const lon2Rad = toRadians(lon2);

  // Haversineの公式
  const deltaLat = lat2Rad - lat1Rad;
  const deltaLon = lon2Rad - lon1Rad;
  const a =
    Math.sin(deltaLat / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) ** 2;
  const c = 2 * Math.asin(Math.sqrt(a));

  // 地球の半径 (キロメートル)
  const R = 6371.0;

  // 距離を計算
  const distance = c * R;

  return distance;
}
