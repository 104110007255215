export function isSP() {
  return (
    navigator.userAgent.match(/iPhone|iPad|Android.+Mobile/) != null ||
    (window.navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 &&
      "ontouchend" in document)
  );
}

export const ShareType = {
  Twitter: Symbol(),
  Line: Symbol(),
  Facebook: Symbol(),
  Instagram: Symbol(),
};
/**
 * シェアリンクを作成する
 * 改行は\r\n
 * @param shareType
 * @param message
 */
export function createShareLink(shareType, message = "") {
  switch (shareType) {
    case ShareType.Twitter:
      return (
        "https://twitter.com/intent/tweet?text=" + encodeURIComponent(message)
      );
    case ShareType.Line:
      return "https://line.me/R/share?text=" + encodeURIComponent(message);
    case ShareType.Facebook:
      return (
        "https://www.facebook.com/share.php?u=" + encodeURIComponent(message)
      );
    case ShareType.Instagram:
      return "https://www.instagram.com/";
    default:
      break;
  }
  return "";
}

// フォント読み込む
/**
 *
 * @param {string} name
 * @param {string} url
 * @param {FontFaceDescriptors} descriptors
 * @returns {Promise<FontFace>}
 */
export function loadFont(
  name,
  url,
  descriptors = {
    style: "normal",
    weight: "400",
  }
) {
  const newFont = new FontFace(name, `url(${url})`, descriptors);
  return new Promise((resolve) => {
    newFont
      .load()
      .then((loaded) => {
        document.fonts.add(loaded);
        // console.log(document.fonts);
        // console.log(`${name}の読み込み完了`);
        resolve(loaded);
      })
      .catch(function (error) {
        console.error(`${name}の読み込みに失敗しました`);
        return error;
      });
  });
}

/**
 *
 * @param {Element} elem
 * @param {string} className
 */
export function addClass(elem, className) {
  if (!elem.classList.contains(className)) {
    elem.classList.add(className);
  }
}
/**
 *
 * @param {Element} elem
 * @param {string} className
 */
export function removeClass(elem, className) {
  if (elem.classList.contains(className)) {
    elem.classList.remove(className);
  }
}

/**
 *
 * @param {Element} elem
 * @param {string} className
 */
export function toggleClass(elem, className) {
  if (elem.classList.contains(className)) {
    elem.classList.remove(className);
    return;
  }
  elem.classList.add(className);
}

export function getTypeName(target) {
  var funcNameRegex = /function (.{1,})\(/;
  var results = funcNameRegex.exec(target.constructor.toString());
  return results && results.length > 1 ? results[1] : "";
}

/**
 * 要素の表示状態を切り替える。
 * @param elem 要素
 * @param active 表示状態
 */
export function setActive(elem, active) {
  if (active) {
    removeClass(elem, "hidden");
  } else {
    addClass(elem, "hidden");
  }
}

const Frame60PerSec = 1 / 60;
export function sleep(ms = Frame60PerSec * 1000) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function timeCoroutine(timeout, action) {
  // Date.now()は現在の時間をUnix時間（1970年1月1日午前0時0分0秒から経過した時間）のミリ秒を返す
  const startTime = Date.now();
  // `timeout`ミリ秒経過するまで無限ループをする
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const diffTime = Date.now() - startTime;
    if (diffTime >= timeout) {
      action?.(1.0);
      return;
    }
    action?.(diffTime / timeout);
    await sleep();
  }
}

/**
 * 条件が一致するまでループする疑似コルーチン
 * @param {()=>boolean} predicate
 * @param {(deltaTime:number)=>{}} action
 * @returns
 */
export async function predicateCoroutine(predicate, action) {
  if (predicate == null || predicate == undefined) {
    return;
  }
  let lastTime = Date.now();
  // `timeout`ミリ秒経過するまで無限ループをする
  while (!predicate()) {
    const nowTime = Date.now();
    const deltaTime = (nowTime - lastTime) / 1000;
    lastTime = nowTime;
    action?.(deltaTime);
    await sleep();
  }
}

export function waitLoad(element) {
  return new Promise((res) => {
    element.addEventListener(
      "load",
      () => {
        res(element);
      },
      { once: true }
    );
  });
}

export function loadImg(element) {
  return new Promise((res) => {
    element.addEventListener(
      "load",
      () => {
        res(element);
      },
      { once: true }
    );
  });
}

/**
 *
 * @param {HTMLElement} element
 * @returns {Promise<Document|null>}
 */
export function loadSVGDocument(element) {
  return new Promise((res) => {
    element.addEventListener(
      "load",
      () => {
        res(element.getSVGDocument());
      },
      { once: true }
    );
  });
}
export function createSVG(path, parent, ...classList) {
  //SVGオブジェクト読み込み用のobjectタグを制作し、popup_bg_rootの階層の一番上に挿入
  let svgobj = document.createElement("object");
  svgobj.type = "image/svg+xml";
  svgobj.data = path;
  parent?.append(svgobj);
  svgobj.classList?.add(classList);
  //SVGオブジェクトの読み込みが完了したら内部関数のsetImagesを処理する
  //onloadは基本的に表示状態(要素が追加されている状態,diplay:noneでない)でないと作動しない
  return loadSVGDocument(svgobj);
}

export function downloadBase64(fileName, base64) {
  const link = document.createElement("a");
  link.download = fileName;
  link.href = base64;
  link.click();
  link.remove();
}

export function toMailLinkHTML(address, text, isBlank = true) {
  return `<a href="mailto:${address}" target="_blank">${text}</a>`;
}
export function toLinkHTML(url, text, isBlank = true) {
  return `<a href="${url}" ${isBlank ? 'target="_blank"' : ""}>${text}</a>`;
}
export function toColorText(colorCode, text) {
  return `<span style="color:${colorCode}">${text}</span>`;
}

/**
 *
 * @param {string} input
 * @param {[[string]]} dict
 * @returns
 */
export function replaceText(input, dict) {
  dict.forEach((c) => {
    input = input.replaceAll(c[0], c[1]);
  });
  return input;
}

/**
 *
 * @param {HTMLElement} elem ターゲット
 * @param {number} threshold 発火する可視範囲
 */
export function scrollVisibleObserve(elem, threshold) {
  // 最低でも1px確保することで動作を保証する
  elem.style.minHeight = "1px";
  return new Promise((res) => {
    const removeThreshold = 0.0;
    const option = {
      // この閥値をを上回るか下回ったときにイベントが発行される。
      threshold: [threshold, removeThreshold],
    };
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        // console.log(entry.intersectionRatio);
        if (threshold <= entry.intersectionRatio) {
          observer.unobserve(entry.target);
          res();
        }
      });
    };
    const observer = new IntersectionObserver(callback, option);
    observer.observe(elem);
  });
}
/**
 *
 * @param {HTMLElement} target
 * @param {MutationObserverInit} options
 * @returns
 */
export function mutationObserve(
  target,
  options = {
    childList: true, //直接の子の変更を監視
    characterData: true, //文字の変化を監視
    characterDataOldValue: true, //属性の変化前を記録
    attributes: true, //属性の変化を監視
    subtree: true, //全ての子要素を監視
  }
) {
  return new Promise((res) => {
    //コールバック関数
    /**
     * @param {MutationRecord[]} mutationsList
     * @param {MutationObserver} observer
     */
    function callback(mutationsList, observer) {
      console.warn("callback");
      res(mutationsList, observer);
      //ターゲット要素の監視を停止
      obs.disconnect();
    }
    //インスタンス化
    const obs = new MutationObserver(callback);
    //ターゲット要素の監視を開始
    obs.observe(target, options);
  });
}

//引数はbase64形式の文字列
export function toBlob(base64) {
  var bin = atob(base64.replace(/^.*,/, ""));
  var buffer = new Uint8Array(bin.length);
  for (var i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  try {
    var blob = new Blob([buffer.buffer], {
      type: "image/png",
    });
  } catch (e) {
    return false;
  }
  return blob;
}

/*
export function fileDownload(url:string) {
    const objXML = new XMLHttpRequest();
    objXML.open("GET", url, true);
    objXML.responseType = "blob";
    objXML.onload = function (oEvent) {
        const objBlob = objXML.response;
        const objURL = window.URL.createObjectURL(objBlob);
        const objLink = document.createElement("a");
        document.body.appendChild(objLink);
        objLink.href = objURL;
        const match = url.match(".+/(.+?)([\?#;].*)?$");
        if(!match) return;
        const filename = match[1];
        objLink.download = filename;
        objLink.target = '_blank';
        objLink.click();
    };
    objXML.send();
}
*/

/**
 * 外部リンクに飛ばす
 * ※GAに遷移状況を知らせる場合、aタグかカスタムイベントでしか取れないため、
 *   基本的にはaタグを使うようにする。
 * @param {string} url
 * @param {*} target
 */
export function openURL(url, target = "_blank") {
  const windowObjectReference = window.open(url, target);
  windowObjectReference?.focus();
}
export function MoveTowardsFloat(current, target, maxDelta) {
  if (Math.abs(target - current) <= maxDelta) return target;
  return current + Math.sign(target - current) * maxDelta;
}
/**
 * 重み付けで抽選を行う
 * ※0を含まないでください
 * @param {[Number]} array 抽選する対象の配列
 * @return 抽選結果(配列の要素)
 */
export function weightedPick(array) {
  let totalWeight = 0;
  let pick = 0;
  let length = array.length;
  // トータルの重みを計算する
  for (let i = 0; i < length; i++) {
    totalWeight += array[i];
  }
  // 抽選する
  let rnd = Math.random() * totalWeight;
  for (let i = 0; i < length; i++) {
    if (rnd < array[i]) {
      // 抽選対象決定
      pick = i;
      break;
    }
    // 次の対象を調べる
    rnd -= array[i];
  }
  return pick;
}
/**
 * 重み付けで抽選を行う
 * @param {Array<Number>} weights 抽選する対象の配列
 * @param {Number} maxPicks 抽選数
 * @return 抽選結果(配列の要素)
 */
export function weightedPicks(weights, maxPicks) {
  let copyWeights = [...weights];
  const len = copyWeights.length;
  let idxArray = new Array(len);
  // ずれたインデックスから復元するためにインデックス番号をマッピング
  for (let i = 0; i < len; i++) {
    idxArray[i] = i;
  }
  let results = [];
  let totalWeight = 0;
  let pick = 0;
  // トータルの重みを計算する
  for (let i = 0; i < len; i++) {
    totalWeight += copyWeights[i];
  }
  // 抽選する
  for (let n = 0; n < maxPicks && idxArray.length > 0; n++) {
    let rnd = Math.random() * totalWeight;
    for (let i = 0; i < idxArray.length; i++) {
      if (rnd < copyWeights[i]) {
        // 抽選対象決定
        pick = i;
        break;
      }
      // 次の対象を調べる
      rnd -= copyWeights[i];
    }
    results.push(idxArray[pick]);
    totalWeight -= copyWeights[pick];
    copyWeights.splice(pick, 1);
    idxArray.splice(pick, 1);
  }
  return results;
}
export function registerDebugCommand(key, callback) {
  window[key] = callback;
}

// CSVを読み込んで配列に変換
export function getCSV(path) {
  return new Promise((res) => {
    var csvData = new Array();
    var req = new XMLHttpRequest(); // HTTPでファイルを読み込むためのXMLHttpRrequestオブジェクトを生成
    req.open("GET", path, true); // アクセスするファイルを指定
    req.send(null); // HTTPリクエストの発行
    req.onload = function () {
      // console.log(req.responseText);
      // 渡されるのは読み込んだCSVデータ
      var CR = String.fromCharCode(13);
      var LF = String.fromCharCode(10);
      var lines = req.responseText.split(CR + LF);
      for (var i = 0; i < lines.length; ++i) {
        var cells = lines[i].split(",");
        if (cells.length != 1) {
          csvData.push(cells);
        }
      }
      res(csvData);
    };
  });
}

/**
 * 重複排除
 * @param {*} source
 * @param {*} callback
 * @returns
 */
export function deduplicationSingle(source) {
  return Array.from(new Set(source));
}
/**
 * 重複排除(条件指定版)
 * @param {*} source
 * @param {*} callback
 * @returns
 */
export function deduplication(source, callback) {
  return Array.from(new Map(source.map((c) => [callback(c), c])).values());
}

/**
 *
 * @param {Date} date1
 * @param {Date} date2
 * @returns {number}
 */
export function getSubDay(date1, date2) {
  date1 = new Date(date1.toDateString());
  date2 = new Date(date2.toDateString());
  var diff = Math.abs(date2.getTime() - date1.getTime());
  return Math.floor(diff / (24 * 60 * 60 * 1000));
}

/**
 * 郵便番号から住所を取得する
 * 入力にハイフンを含んでいても問題ない
 * 使用API:https://zipcloud.ibsnet.co.jp/doc/api
 * @param {string} postalCode
 * @returns {{address1:string,address2:string,address3:string,kana1:string,kana2:string,kana3:string,prefcode:string,zipcode:string,}|null}
 */
export async function postalCodeToAddress(postalCode) {
  try {
    const res = await fetch(
      `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode.replace(
        "-",
        ""
      )}&limit=1`
    );
    if (!res.ok) {
      return null;
    }
    const data = await res.json();
    if (data.results) {
      return data.results[0];
    }
  } catch {
    // alert("住所の取得に失敗しました。");
    // alert(reason);
  }
  return null;
}

export function shuffleArray(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // 配列をシャッフルする
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

/**
 *配列を分割する
 * @param {Array} array
 * @param {number} number
 * @returns
 */
export const sliceByNumber = (array, number) => {
  const length = Math.ceil(array.length / number);
  return new Array(length)
    .fill()
    .map((_, i) => array.slice(i * number, (i + 1) * number));
};

export function zeroPadding(num, len) {
  return (Array(len).join("0") + num).slice(-len);
}

/**
 * 対象下のノードを再帰的に制御する
 * @param {Node} node
 * @param {(node:Node)=>boolean} action
 * @returns
 */
export function RecursiveNode(node, action) {
  while (node != null) {
    if (node instanceof Array) {
      node.forEach((c) => {
        RecursiveNode(c, action);
      });
      node = node.nextSibling;
    } else {
      if (!action(node)) return;
      // 子を再帰
      RecursiveNode(node.firstChild, action);
      // 次のノードを探査
      node = node.nextSibling;
    }
  }
}

/**
 * JavaやC#のformatメソッドのように、特定の文字列のプレースホルダを引数で渡された文字で置き換える
 *
 * @param {string} str 置換前文字列 プレースホルダを`{0}`, `{1}`の形式で埋め込む
 * @param {unknown[]} ...args 第2引数以降で、置換する文字列を指定する
 *
 * 参考
 * - https://qiita.com/YOS0602/items/8eadf8f7743ebdc5946c
 * - https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format/4673436#4673436
 * - https://trueman-developer.blogspot.com/2015/11/typescriptjavascript.html
 */
export const ReplaceFormat = (str, ...args) => {
  for (const [i, arg] of args.entries()) {
    const regExp = new RegExp(`\\{${i}\\}`, "g");
    str = str.replace(regExp, arg);
  }
  return str;
};

export const GetFunctionArgNames = (func) => {
  return /\((.+)\)/.exec(String(func))[1].split(",");
};

// CSS文字列を変換し、セレクタに変更を加える関数
/**
 *
 * @param {string} cssString
 * @param {(selector:string)=>string} modifySelectorAction
 * @param {(properties:string)=>string} modifyPropertyAction
 * @returns
 */
export function modifyCSS(
  cssString,
  modifySelectorAction = undefined,
  modifyPropertyAction = undefined
) {
  // CSSルールごとに分割
  const rules = cssString.split("}");

  // 新しいCSS文字列を格納する変数
  let modifiedCSSString = "";

  for (const rule of rules) {
    // ルール内のセレクタとプロパティを抽出
    const parts = rule.split("{");
    if (parts.length === 2) {
      const selectorList = parts[0].split(","); // セレクターリストをカンマで分割
      const properties = parts[1].trim();

      // セレクタリストの各セレクタに指定の変更を加える
      const modifiedSelectors = selectorList
        .map((selector) => {
          return modifySelectorAction?.(selector) ?? selector;
        })
        .join(",");

      // プロパティに指定の変更を加える
      const modifiedProperties =
        modifyPropertyAction?.(properties) ?? properties;

      // 新しいルールを生成して追加
      if (modifiedCSSString !== "") {
        modifiedCSSString += "\n"; // 前のルールとの間に改行を挿入
      }
      // console.log(properties);
      modifiedCSSString += `${modifiedSelectors} {\n  ${modifiedProperties}\n}`;
    } else {
      // ルールが正しくない場合、そのまま追加
      modifiedCSSString += rule;
    }
  }
  return modifiedCSSString;
}
// セレクタに指定のセレクタを追加するヘルパー関数
export function addSelectorToSelector(selector, addSelector) {
  const trimmedSelector = selector.trim();
  return `${addSelector} ${trimmedSelector}`;
}
export function toLocalStyle(addSelector, cssString) {
  return modifyCSS(cssString, (selector) => {
    return addSelectorToSelector(selector, addSelector);
  });
}
/**
 * 入力として関数を受け取り、一度のみの呼び出しを保証する関数を返します。
 * @param {()=>{}} callback
 * @returns
 */
export function createFunctionWithOnce(callback) {
  let called = false;
  function fn(...params) {
    // console.log(params);
    if (!called) {
      called = true;
      callback(...params);
    } else {
      console.warn("二度呼びを防止しました");
    }
  }
  return fn;
}

/**
 *
 * @param {number} hue
 * @param {number} saturation
 * @param {number} value
 * @returns
 */
export function hsv2rgb(hue, saturation, value) {
  let result = false;
  if (
    (hue || hue === 0) &&
    hue <= 360 &&
    (saturation || saturation === 0) &&
    saturation <= 100 &&
    (value || value === 0) &&
    value <= 100
  ) {
    let red = 0,
      green = 0,
      blue = 0,
      i = 0,
      f = 0,
      q = 0,
      p = 0,
      t = 0;

    hue = Number(hue) / 60;
    saturation = Number(saturation) / 100;
    value = Number(value) / 100;

    if (saturation === 0) {
      red = value;
      green = value;
      blue = value;
    } else {
      i = Math.floor(hue);
      f = hue - i;
      p = value * (1 - saturation);
      q = value * (1 - saturation * f);
      t = value * (1 - saturation * (1 - f));

      switch (i) {
        case 0:
          red = value;
          green = t;
          blue = p;
          break;
        case 1:
          red = q;
          green = value;
          blue = p;
          break;
        case 2:
          red = p;
          green = value;
          blue = t;
          break;
        case 3:
          red = p;
          green = q;
          blue = value;
          break;
        case 4:
          red = t;
          green = p;
          blue = value;
          break;
        case 5:
          red = value;
          green = p;
          blue = q;
          break;
      }
    }
    result = `rgb(${Math.round(red * 255)}, ${Math.round(
      green * 255
    )}, ${Math.round(blue * 255)})`;
  }

  return result;
}

/**
 * 文字列が Nullまたはundefined かブランクであるかを問い合わせる。
 * @param {string} value 検査する文字列
 * @returns {boolean} 条件を満たしている場合 true
 */
export function isNullOrEmpty(value) {
  return typeof value === "undefined" || value === null || value.length === 0;
}

/**
 * 入力された文字列がnull
 * @param {string|null|undefined} str
 * @returns
 */
export function isNullOrWhitespace(str) {
  if (typeof str === "undefined" || str == null) return true;
  return !/\S/.test(str); // Does it fail to find a non-whitespace character?
}

/**
 * @type {Object} left
 * @type {Object} right
 * @return {boolean}
 */
export function isEqualObjectValue(left, right) {
  return JSON.stringify(left) === JSON.stringify(right);
}
/**
 * deepEqual => 再起的に、JavaScriptの変数の値が、同じものかを Checkする Func
 */
function deepEqual(obj1, obj2) {
  // 両方がオブジェクトであるかどうかをチェック
  if (obj1 && obj2 && typeof obj1 === "object" && typeof obj2 === "object") {
    // オブジェクトのkeyを比較
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    // keyが同じ場合は、再帰的に値を比較
    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  } else {
    // オブジェクトでない場合、単純に値を比較
    return obj1 === obj2;
  }
}

// /**
//  * @template {keyof WorkInfo} T1
//  * @template {WorkInfo[T1] extends object ? WorkInfo[T1] : undefined} T2
//  * @param {T1} fieldName
//  * @param {number} index
//  * @param {T2} value
//  */
// const setItem = (fieldName, index, value) => {
//   tempAttendanceInfo.value = tempAttendanceInfo.value.map((c, i) =>
//     i === index ? { ...c, [fieldName]: value } : c,
//   );
// };

const ranges = [
  "[\ud800-\ud8ff][\ud000-\udfff]", // 基本的な絵文字除去
  "[\ud000-\udfff]{2,}", // サロゲートペアの二回以上の繰り返しがあった場合
  "\ud7c9[\udc00-\udfff]", // 特定のシリーズ除去
  "[0-9|*|#][\uFE0E-\uFE0F]\u20E3", // 数字系絵文字
  "[0-9|*|#]\u20E3", // 数字系絵文字
  "[©|®|\u2010-\u3fff][\uFE0E-\uFE0F]", // 環境依存文字や日本語との組み合わせによる絵文字
  "[\u2010-\u2FFF]", // 指や手、物など、単体で絵文字となるもの
  "\uA4B3", // 数学記号の環境依存文字の除去
];
const surrogatePairCode = [65038, 65039, 8205, 11093, 11035];

/**
 * 絵文字を除去する
 * 参考:https://highmoon-miyabi.net/blog/2022/02/21_000588.html
 * @param {string} in_value
 * @returns
 */
export const removeEmoji = (in_value) => {
  const reg = new RegExp(ranges.join("|"), "g");
  let retValue = in_value.replace(reg, "");
  // パターンにマッチする限り、除去を繰り返す（一回の正規表現除去では除去しきないパターンがあるため）
  while (retValue.match(reg)) {
    retValue = retValue.replace(reg, "");
  }

  // 二重で絵文字チェック（4バイト、サロゲートペアの残りカス除外）
  return retValue.split("").reduce((p, c) => {
    const code = c.charCodeAt(0);
    if (
      encodeURIComponent(c).replace(/%../g, "x").length < 4 &&
      !surrogatePairCode.some((codeNum) => code == codeNum)
    ) {
      return (p += c);
    } else {
      return p;
    }
  }, "");
};

/**
 * 絵文字が入っているかをチェック
 * @param {string} in_value
 * @returns
 */
export const hasEmoji = (in_value) => {
  // ※を許容する
  in_value = in_value.replaceAll("※", "");

  const reg = new RegExp(ranges.join("|"), "g");
  if (in_value.match(reg)) {
    return true;
  }
  let retValue = in_value.replace(reg, "");
  while (retValue.match(reg)) {
    return true;
  }
};

/**
 * @template T
 * @param {Array<T>} arrays
 * @param {number?} minArrayCount
 * @returns
 */
function findClosestMatch(arrays, minArrayCount = arrays.length) {
  const matchingElements = findClosestMatchAll(arrays, minArrayCount);
  // 一番先頭に近い要素を返す（存在しない場合はnull）
  return matchingElements.length > 0 ? matchingElements[0] : null;
}

/**
 * @template T
 * @param {Array<T>} arrays
 * @param {number?} minArrayCount
 * @returns
 */
function findClosestMatchAll(arrays, minArrayCount = arrays.length) {
  if (arrays.length < 2 || minArrayCount <= 0) {
    console.error("配列は2つ以上、minArrayCountは1以上である必要があります。");
    return;
  }

  const elementCountMap = new Map();

  // 各配列の各要素についてカウントを行う
  for (const array of arrays) {
    const uniqueElements = Array.from(new Set(array)); // 重複を除いた要素のみを対象にする
    for (const element of uniqueElements) {
      if (elementCountMap.has(element)) {
        elementCountMap.set(element, elementCountMap.get(element) + 1);
      } else {
        elementCountMap.set(element, 1);
      }
    }
  }
  // 指定した数以上の配列に含まれている要素を取得
  return Array.from(elementCountMap.keys()).filter(
    (element) => elementCountMap.get(element) >= minArrayCount
  );
}

/**
 * C#System.Flags用のHasFlagを模したもの
 * 参考
 * - https://note.dokeep.jp/post/csharp-enum-flags/
 * @param {number} value
 * @param {number} idx
 * @return {boolean}
 */
export function HasFlag(value, idx) {
  return ((value >> idx) & 1) == 1;
}

export function addFlag(value, idx) {
  return value | (1 << idx);
}
export function removeFlag(value, idx) {
  return value & ~(1 << idx);
}

/**
 * enumFlagの値をnumberのリストに変換したもの
 * @param {number} value
 * @returns {Array<number>}
 */
export function GetFlagIndex(value) {
  const result = [];
  let cnt = 0;
  while (value !== 0) {
    if ((value & 1) === 1) {
      result.push(cnt);
    }
    value = value >> 1;
    cnt++;
  }
  return result;
}

/**
 *
 * @param {string} str
 */
export function splitUnit(str) {
  const matches = str.match(/([+-]?\d+\.?\d*)(\D+)/);
  if (matches) {
    const [, value, unit] = matches;
    // console.log(matches);
    return { value, unit };
  } else {
    return null;
  }
}

/**
 * Webpをサポートしているかをチェック
 * @returns {Promise<boolean>}
 */
export function checkSupportWebp() {
  return new Promise((res) => {
    const webP = new Image();
    webP.src =
      "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
    webP.onload = webP.onerror = function () {
      res(webP.naturalHeight === 2);
    };
  });
}

/**
 * 拡張子を変更する(例[.png]→[.webp])
 * @param {string} str
 */
export function changeExt(str, newExt) {
  return str?.replace(/\.[^.]+$/, newExt);
}

class UrlParams {
  /**
   * コンストラクタ。URLを解析してクエリパラメータを抽出する。
   * @param {string} url - 解析するURL
   */
  constructor(url) {
    if (!url) {
      throw new Error("URLは必須です");
    }
    this.url = url;
    this.params = this._parseUrlParams(this.url);
  }

  /**
   * URLのクエリパラメータを解析してオブジェクトとして返すプライベートメソッド
   * @private
   * @param {string} url - 解析するURL
   * @returns {Object} クエリパラメータのキーと値のオブジェクト
   */
  _parseUrlParams(url) {
    const queryString = url.split("?")[1] || "";
    if (!queryString) return {};

    const params = {};
    queryString.split("&").forEach((param) => {
      const [key, value] = param.split("=").map(decodeURIComponent);
      if (!key) return;

      if (params[key] !== undefined) {
        params[key] = [].concat(params[key], value);
      } else {
        params[key] = value;
      }
    });

    return params;
  }

  /**
   * 指定されたキーのクエリパラメータの値を取得するメソッド
   * @param {string} key - クエリパラメータのキー
   * @returns {string|string[]} キーに対応する値
   */
  get(key) {
    return this.params[key];
  }

  /**
   * 指定されたキーのクエリパラメータの値を設定するメソッド
   * @param {string} key - クエリパラメータのキー
   * @param {string} [value] - キーに対応する値
   * @returns {UrlParams} this - メソッドチェーンのために自身を返す
   */
  set(key, value) {
    this.params[key] = value;
    return this;
  }

  /**
   * 現在のクエリパラメータをクエリ文字列として返すメソッド
   * @returns {string} クエリ文字列
   */
  toString() {
    const queryString = Object.keys(this.params)
      .map((key) => {
        const value = this.params[key];
        if (Array.isArray(value)) {
          return value
            .map(
              (v) =>
                `${encodeURIComponent(key)}${
                  v ? `=${encodeURIComponent(v)}` : ""
                }`
            )
            .join("&");
        }
        return `${encodeURIComponent(key)}${
          value ? `=${encodeURIComponent(value)}` : ""
        }`;
      })
      .join("&");
    return queryString ? `?${queryString}` : "";
  }

  /**
   * 指定されたキーのクエリパラメータを削除するメソッド
   * @param {string} key - 削除するクエリパラメータのキー
   * @returns {UrlParams} this - メソッドチェーンのために自身を返す
   */
  delete(key) {
    delete this.params[key];
    return this;
  }

  /**
   * URL全体を返すメソッド（ベースURL＋クエリパラメータ）
   * @returns {string} URL全体
   */
  getUrl() {
    const baseUrl = this.url.split("?")[0];
    return `${baseUrl}${this.toString()}`;
  }

  /**
   * クエリパラメータの数を返すゲッタープロパティ
   * @returns {number} クエリパラメータの数
   */
  get size() {
    return Object.keys(this.params).length;
  }
}
/**
 *
 * @param {string} url
 * @param {string} key
 * @param {string} [value]
 */
export function addURLParam(url, key, value) {
  // const urlObj = new URL(url);
  // urlObj.searchParams.set(key, value);
  // urlObj.search = urlObj.search.replaceAll("=undefined", "");
  // return urlObj.href;

  // // '?' 以降のクエリ部分を抽出する
  // const queryString = url.split("?")[1] || "";

  // // クエリパラメータがない場合は空のオブジェクトを返す
  // if (!queryString) console.log(queryString);
  // query = queryString.split("&").reduce((params, param) => {
  //   const [key, value] = param.split("=").map(decodeURIComponent);
  //   params[key] = value;
  //   return params;
  // }, {});

  // // URLにパラメーターが既についているか判断する。
  // return url + (url.match(/\?/) ? "&" : "?") + key + (value ? "=" + value : "");

  return new UrlParams(url).set(key).getUrl();
}

/**
 * 再帰的にクラスインスタンスをオブジェクトへ変換する
 * @template T
 * @param {T} instance
 * @returns {T}
 */
export function convertInstanceToObject(instance, seen = new Map()) {
  if (instance === null || typeof instance !== "object") {
    return instance; // プリミティブ型はそのまま返す
  }

  if (Array.isArray(instance)) {
    return instance.map((item) => convertInstanceToObject(item, seen)); // 配列の各要素を再帰的に処理
  }

  if (seen.has(instance)) {
    return seen.get(instance); // 既に処理したオブジェクトは再利用
  }

  const obj = {};

  // 先にマップに登録して、再帰呼び出しで参照されるようにする
  seen.set(instance, obj);

  // プロパティをコピー
  for (const key of Object.keys(instance)) {
    obj[key] = convertInstanceToObject(instance[key], seen); // 再帰的に処理
  }

  // メソッドをコピー
  for (const key of Object.getOwnPropertyNames(
    Object.getPrototypeOf(instance)
  )) {
    if (key !== "constructor" && typeof instance[key] === "function") {
      obj[key] = instance[key].bind(instance);
    }
  }

  return obj;
}

/**
 * クラスとオブジェクトのプロパティ名を比較する関数
 * @param {Object} object - 比較対象のオブジェクト
 * @param {Function} classType - 比較対象のクラス
 * @returns {boolean} - プロパティ名が一致する場合は true、それ以外の場合は false
 */
export function compareClassAndObject(object, classType) {
  if (!object) {
    return false;
  }
  // クラスのプロトタイプからプロパティ名の配列を取得
  const instanceKeys = Object.keys(new classType());
  const objectKeys = Object.keys(object);

  // console.log(instanceKeys);
  // console.log(objectKeys);
  // プロパティの数が一致しなければ false を返す
  if (instanceKeys.length !== objectKeys.length) {
    return false;
  }

  // クラスのすべてのプロパティ名がオブジェクトのプロパティ名に含まれているかを確認
  return instanceKeys.every((key) => objectKeys.includes(key));
}

/**
 * 配列内の特定のキーで重複する要素を排除し、元のデータ形式で返します。
 *
 * @template T - オブジェクトの型
 * @param {Array<T>} array - 元の配列
 * @param {keyof T} key - 重複を排除したいキー
 * @returns {Array<T>} 重複が排除された新しい配列
 */
export function removeDuplicates(array, key) {
  if (!Array.isArray(array)) {
    return null;
  }
  // Mapを使用して重複を排除
  const map = new Map();

  // 配列をループしてMapに追加
  for (const item of array) {
    // console.log(item);
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  }

  // Mapの値を配列に変換して返す
  return Array.from(map.values());
}

/**
 * 現在の時間とランダムな要素に基づいて、アルファベット文字のみで構成されたユニークなクラスを生成します。
 * @param {number} [myStrong=1000] - ランダムなアルファベット文字列生成のための係数。デフォルトは1000。
 * @returns {string} アルファベット文字のみで構成されたユニークなクラス名。
 */
export function getUniqueClassName(myStrong = 1000) {
  const chars = "abcdefghijklmnopqrstuvwxyz";
  const charsLength = chars.length;
  const logLength = Math.ceil(Math.log2(myStrong));
  const now = Date.now();

  // 現在の時間に基づく文字列を生成
  let timePart = "";
  let time = now;
  while (time > 0) {
    timePart += chars[time % charsLength];
    time = Math.floor(time / 10);
  }
  // myStrongに比例した長さのランダムな文字列を生成
  let randomPart = "";
  for (let i = 0; i < logLength; i++) {
    randomPart += chars[Math.floor(Math.random() * charsLength)];
  }
  return timePart + randomPart;
}

/**
 * 指定した間隔内で複数回発生する処理の連続部分をスキップするスロットルイベントを作成します。
 * @template T
 * @param {T} action - 実行したい関数。イベントと同じ引数を受け取ります。
 * @param {number} interval - 処理が実行されるまでの遅延時間（ミリ秒）。。
 * @returns {T} 指定した遅延時間後に実行されるデバウンスされた関数を返します。
 */
export function createDebounceFunction(action, interval = 1000 / 60) {
  // 実行処理のタイムアウトハンドル
  let timeoutId = -1;
  // 最後の処理を保持する
  let lastAction = null;

  // 複数回の処理があるか
  // let isDuplicateCall = false;

  // 即時実行を行うかのフラグ(連続が発生していないとき、最初の入力は即時に反映される)
  let isFirstCall = true;
  // 初回即時実行フラグ再有効化処理のタイムアウトハンドル
  let firstCallTimeOut = -1;

  return function (...args) {
    lastAction = () => action(...args);
    if (firstCallTimeOut !== -1) {
      clearTimeout(firstCallTimeOut);
      firstCallTimeOut = -1;
    }
    if (timeoutId !== -1) {
      // isDuplicateCall = true;
      return;
    } else {
      // 一度は即時実行
      if (isFirstCall) {
        // console.log("即時実行");
        lastAction?.();
        lastAction = null;
        isFirstCall = false;
        // isDuplicateCall = false;
      }
      timeoutId = setTimeout(() => {
        // if (isDuplicateCall) {
        lastAction?.();
        // }
        firstCallTimeOut = setTimeout(() => {
          isFirstCall = true;
          firstCallTimeOut = -1;
        }, interval);
        timeoutId = -1;
      }, interval);
    }
  };
}

/**
 * リソースを取得し、進捗度を追跡する関数
 *
 * @param {string} url - リソースのURL
 * @param {XMLHttpRequestResponseType} responseType - 取得するデータのレスポンスタイプ (例: 'blob', 'arraybuffer', 'json' など)
 * @param {(progress:number) => void} onProgress - 進捗度 (0〜100) を受け取るコールバック関数
 * @returns {Promise<any>} - リクエストが成功するとレスポンスを返す Promise
 */
export function fetchWithProgress(url, responseType, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // 非同期でリソースを取得
    xhr.open("GET", url, true);
    xhr.responseType = responseType;

    // リクエストの進行状況を追跡
    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        onProgress((event.loaded / event.total) * 100);
      }
    };

    xhr.onload = () => {
      // リクエストが正常に完了した場合
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        // ステータスコードがエラー範囲にある場合
        reject(new Error(`Request failed with status ${xhr.status}`));
      }
    };

    // リクエストが失敗した場合
    xhr.onerror = () => {
      reject(new Error("Request failed"));
    };

    // リクエストを送信
    xhr.send();
  });
}

/**
 * @template T, U
 * @param {T} target - プロパティを抽出する対象オブジェクト
 * @param {U} reference - 抽出するプロパティ名を含むオブジェクト
 * @returns {Pick<T, Extract<keyof T, keyof U>>} - `reference`オブジェクトに含まれるキーと一致する`target`のプロパティのみを持つオブジェクト
 */
export function extractMatchingProperties(target, reference) {
  return Object.keys(reference).reduce((result, key) => {
    if (key in target) {
      result[key] = target[key];
    }
    return result;
  }, {});
}

/**
 * 現在のURLに指定されたパラメータを追加してリロードします。
 *
 * @param {string} paramName - 追加するクエリパラメータの名前。
 * @param {string} paramValue - 追加するクエリパラメータの値。
 * @returns {void} この関数は値を返しません。
 *
 * @example
 * reloadWithParams('exampleParam', 'exampleValue');
 */
export function reloadWithParams(paramName, paramValue) {
  // 現在のURLを取得
  const currentUrl = new URL(window.location.href);

  // 既存のパラメータを設定
  currentUrl.searchParams.set(paramName, paramValue);

  // 新しいURLでリロード
  window.location.href = currentUrl.href;
}
/**
 * 指定されたクエリパラメータの値を取得します。
 *
 * @param {string} paramName - 取得したいクエリパラメータの名前。
 * @returns {string|null} パラメータの値。パラメータが存在しない場合はnullを返します。
 *
 * @example
 * // 例: 'exampleParam'というパラメータの値を取得する。
 * const value = getParam('exampleParam');
 * console.log(value); // パラメータの値が出力されます。
 */
export function getParam(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}
