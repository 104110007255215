/**
 * 入力として関数を受け取り、新しい関数を返します。
 * 返された関数がタイムアウト（デフォルトでは 1 秒後）までに呼び出されると、
 * タイムアウトがクリアされ、入力された関数が呼び出されます。
 * タイムアウトまでに呼び出されなかった場合でも同様に、入力された関数が呼び出されます。
 * @param {()=>{}} callback
 * @param {number} [opt_timeout]
 * @returns
 */
function createFunctionWithTimeout(callback, opt_timeout) {
  var called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 2000);
  return fn;
}

/**
 * GA送信
 * @param {string} eventName
 * @param {object} [sendObject={}]
 * @returns {Promise<any>}
 */
export function sendGA(eventName, sendObject = {}) {
  return new Promise((res) => {
    if ("gtag" in window) {
      sendObject.event_callback = createFunctionWithTimeout(function () {
        // #if DEV
        console.log(
          `送信しました:event,${eventName},${JSON.stringify(sendObject)}`
        );
        // #endif
        // イベントトラッキングが送信されたら呼び出される
        res();
      });
      window.gtag("event", eventName, sendObject);
    } else if ("dataLayer" in window) {
      sendObject.event = eventName;
      sendObject.eventCallback = createFunctionWithTimeout(function () {
        // #if DEV
        console.log(`送信しました:${JSON.stringify(sendObject)}`);
        // #endif
        res();
      });
      // sendObject.eventTimeout = 2000;
      window.dataLayer.push(sendObject);
    }
  });
}
