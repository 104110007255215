import MotionBase from "../../lib/MotionBase";
import AspectFitter from "../../lib/AspectFitter";
import { addClass, removeClass } from "../../common/common";
import { onMount } from "solid-js";

// import { userData } from "../js/UserData";
import "./map.css";
import myConfig, { targetLoc } from "../../pageConfig";
import { initHowler, make_sound, make_sounds } from "../../lib/Howler";
import { fetchWithProgress } from "../../common/extra";
import { timeInterval } from "rxjs";

function Title({ stat }) {
  const nextPage = "Check";

  const soundPath = myConfig.howlerInfo.mainPath;
  let soundFiles = stat.state.sounds.value;

  let currentIndex = 0;

  function deleteUrlParam() {
    // 現在のURLを取得
    const url = new URL(window.location);

    // 削除したいパラメーター名を指定
    const parametersToRemove = ["recovery"]; // ここに削除したいパラメーターを追加

    // 指定したパラメーターを削除
    parametersToRemove.forEach((param) => {
      url.searchParams.delete(param);
    });

    // 新しいURLにリダイレクト（履歴を変更せずにURLを更新）
    window.history.replaceState({}, document.title, url);
  }

  const setTestSound = () => {
    const path = soundPath + myConfig.howlerInfo.testSound + ".mp3";
    const sound = new Howl({
      src: [path],
      onload: function () {
        // ロード時次をロード
        // console.log(`Loaded: ${myConfig.howlerInfo.testSound}`);
        playNextSound(); // 次の音声を再生
      },
      onend: function () {
        // console.log(`Finished: ${myConfig.howlerInfo.testSound}`);
      },
      onloaderror: function (id, error) {
        // エラー時再ロード
        // console.error(
        //   `Error loading ${myConfig.howlerInfo.testSound}: ${error}`
        // );
        setTimeout(() => {
          setTestSound();
        }, 1000);
      },
    });
    stat.state.testSound.value = sound;
  };

  const playNextSound = () => {
    if (currentIndex < soundFiles.length) {
      // 既にロードしてる時
      if (soundFiles[currentIndex] in stat.state.soundsList.value) {
        currentIndex++;
        playNextSound();
      }
      const path = soundPath + soundFiles[currentIndex] + ".mp3";
      const sound = new Howl({
        src: [path],
        onload: function () {
          // ロード時次をロード
          if (!(soundFiles[currentIndex] in stat.state.soundsList.value))
            // 音声を登録
            stat.state.soundsList.value = {
              ...stat.state.soundsList.value,
              [soundFiles[currentIndex]]: sound,
            };
          currentIndex++;
          playNextSound(); // 次の音声を再生
        },
        onend: function () {},
        onloaderror: function (id, error) {
          // エラー時再ロード
          // console.error(`Error loading ${soundFiles[currentIndex]}: ${error}`);
          setTimeout(() => {
            playNextSound();
          }, 1000);
        },
      });
    } else {
      // console.log("All sounds have been played.");
    }
  };
  /**
   * タイトル後のページを設定する
   */
  onMount(() => {});

  return (
    <MotionBase>
      <div class="absoluteCenter show h-full w-full flexCenter bg-[#f1f0ea]">
        <div class="h-full w-full flexCenter fitScreenY">
          <div class="w-full h-full eGrid-row-[1108,830,278]">
            <div class="h-full w-full">
              <img
                class="w-full max-h-full"
                src="./images/start/main.svg"
                alt=""
              />
            </div>
            <div class="flex justify-center items-center w-full h-full">
              <div class="flex justify-around items-center flex-col w-full h-[80%]">
                <div class="w-per-[502]">
                  <AspectFitter w={502} h={110}>
                    <button
                      class="btn3D btn-main w-full h-full"
                      onclick={(ev) => {
                        removeClass(ev.target, "pointer-events-auto");
                        deleteUrlParam();
                        setTestSound();
                        stat.changePage(nextPage);
                      }}
                    >
                      <p class="text-customBase text-5xl font-noto font-black">
                        はじめる
                      </p>
                    </button>
                  </AspectFitter>
                </div>
                <button
                  class="btn"
                  onclick={(ev) => {
                    removeClass(ev.target, "pointer-events-auto");
                    stat.pageState.popups.Terms.value = true;
                    addClass(ev.target, "pointer-events-auto");
                  }}
                >
                  <p class="underline font-noto font-black text-customBase text-2xl btn-link">
                    利用規約
                  </p>
                </button>
                <p class="font-medium text-xl font-noto">
                  © ITABASHI CITY OFFICE All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MotionBase>
  );
}

export default Title;
