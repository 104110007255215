import { createSignal, onCleanup, onMount } from "solid-js";
import MotionBase from "../../lib/MotionBase";
import BlackPanel from "../../lib/BlackPanel";
import { isSP, vpMin } from "../../common/common";
import ScrollableArea from "../../lib/ScrollableArea";
import SignalProperty from "../../common/SignalProperty";
import { removeClass } from "../../common/common";
import AspectFitter from "../../lib/AspectFitter";
import {
  change_seek,
  end_sound,
  change_firstStart,
  make_sound,
} from "../../lib/Howler";

import "./inputRange.css";
import myConfig from "../../pageConfig";
import { style } from "solid-js/web";
// import "../../../css/modal.css";

const Complete = ({ ...stat }) => {
  function resetData() {
    // 特定のキーを削除する
    localStorage.removeItem("spot_data");

    // 現在のURLを取得
    const url = new URL(window.location);

    // 削除したいパラメーター名を指定
    const parametersToRemove = ["jump", "recovery"]; // ここに削除したいパラメーターを追加

    // 指定したパラメーターを削除
    parametersToRemove.forEach((param) => {
      url.searchParams.delete(param);
    });

    // 新しいURLにリダイレクト（履歴を変更せずにURLを更新）
    window.history.replaceState({}, document.title, url);
  }

  return (
    <MotionBase>
      <BlackPanel>
        <div class="h-full w-full flex justify-center items-center">
          <div class="w-full h-full fitScreenY">
            <div class="w-full h-full flex justify-center items-center">
              <div class="term w-per-[654] rounded-sm flexCenter">
                <AspectFitter w={654} h={1018}>
                  <div
                    class="w-full h-full eGrid-row-[1018,831,187] bg-white rounded-3xl bg-no-repeat bg-cover"
                    style={{
                      "background-image": "url(./images/complete/bg.svg)",
                    }}
                  >
                    <div class="flex flex-col items-center justify-start">
                      <p class="text-customBase fontSizeNormal-[44] font-noto  tracking-widest font-black text-center mt-10">
                        お疲れ様でした！ <br />
                        音声ガイドツアーを <br /> 終了します
                      </p>
                      <img
                        class="w-per-[587.1431,654] mt-14"
                        src="./images/complete/stamp.png"
                        alt=""
                      />
                    </div>
                    <div class="w-full h-full flexCenter">
                      <div class="w-per-[441]">
                        <AspectFitter w={441} h={113}>
                          <button
                            class="btn3D btn-main w-full h-full"
                            onclick={(ev) => {
                              removeClass(ev.target, "pointer-events-auto");
                              resetData();
                              window.location.reload(); // ページをリロード
                            }}
                          >
                            <p class="text-customBase fontSizeNormal-[42] font-noto font-black">
                              TOPへ戻る
                            </p>
                          </button>
                        </AspectFitter>
                      </div>
                    </div>
                  </div>
                </AspectFitter>
              </div>
            </div>
          </div>
        </div>
      </BlackPanel>
    </MotionBase>
  );
};
export default Complete;
