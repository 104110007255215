/* @refresh reload */
import { render } from "solid-js/web";

import init from "./common/init";
import pageConfig from "./common/pageConfig";
import App from "./App";
import SignalProperty from "./common/SignalProperty";

import myConfig, { targetLoc } from "./pageConfig";

import "./common/common.css";
import { make_sound } from "./lib/Howler";
import { getParam } from "./common/common";
import { error } from "./common/log";

const param = getParam("jump");
const recoveryPoint = getParam("recovery");

// 設定など
const stat = {
  ...pageConfig(),
  state: {
    isLoading: new SignalProperty(true),
    saveData: new SignalProperty({}),
    testSound: new SignalProperty(
      undefined
      // make_sound(myConfig.howlerInfo.testSound, myConfig.howlerInfo.mainPath)
    ),
    sounds: new SignalProperty(undefined),
    soundsList: new SignalProperty({}),
    spots: new SignalProperty(targetLoc),
    isNextMusic: new SignalProperty(false),
    isSpotMusic: new SignalProperty(true),
    isInSpot: new SignalProperty(false),
    isPlay: new SignalProperty(false),
    currentKey: new SignalProperty(undefined),
    currentTarget: new SignalProperty(undefined),
    jumpParam: new SignalProperty(param),
    recoveryPoint: new SignalProperty(recoveryPoint),
  },
};
init(stat);

render(() => <App {...stat} />, document.getElementById("root"));

if (myConfig.DebugMode) {
  window.stat = stat;
  console.error("DebugModeがTrueです");
}

// デバイスからのリロードを設定するイベントリスナー
window.addEventListener("load", () => {
  // 現在のURLを取得
  const url = new URL(window.location);

  // 削除したいパラメーター名を指定
  const parametersToRemove = ["jump"]; // ここに削除したいパラメーターを追加

  // 指定したパラメーターを削除
  parametersToRemove.forEach((param) => {
    url.searchParams.delete(param);
  });

  // 新しいURLにリダイレクト（履歴を変更せずにURLを更新）
  window.history.replaceState({}, document.title, url);
});

function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

if (!storageAvailable("localStorage")) {
  alert(
    "現在の環境ではセーブデータが保存されません（コンテンツ自体は問題なくお楽しみいただけます）"
  );
} else {
  console.log("use localStorage");
}
