/**
 * @param { {
 * w:number,
 * h:number,
 * children: JSX.Element | ((item: NonNullable<T>) => JSX.Element)
 * }} props
 * @returns {() => JSX.Element}
 */
function AspectFitter({ w, h, ...stat }) {
  return (
    <div class={`relative w-full h-auto`}>
      <div class="block w-full content-[''] p-0" style={`padding-top:${(h / w) * 100}%`}></div>
      <div class="absolute top-0 left-0 right-0 bottom-0">{stat.children}</div>
    </div>
  );
}
export default AspectFitter;
