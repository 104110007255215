import { filter, fromEvent, startWith } from "rxjs";
import { RecursiveNode, isSP } from "./common";
const SaveableImageClass = "save";
export default function init(stat) {
  // DOMの変更に対してメニュー表示をfalseにする
  new MutationObserver((mutations, observer) => {
    for (const mutation of mutations) {
      for (const addedNode of mutation.addedNodes) {
        RecursiveNode(addedNode, (node) => {
          // console.log(node);
          if (
            node instanceof HTMLElement &&
            node.tagName === "IMG" &&
            !node.classList.contains(SaveableImageClass)
          ) {
            // console.log(node);
            node.oncontextmenu = false;
            // console.log("New img element added:", node);
          }
          return true;
        });
      }
    }
  }).observe(document.body, {
    childList: true, //直接の子の変更を監視
    subtree: true, //全ての子要素を監視
  });

  // safariのヘッダーサイズに対応させるためのプロパティ
  const viewport = window.visualViewport;
  if (viewport) {
    viewport.addEventListener("resize", (ev) => {
      if (viewport.height < document.documentElement.clientHeight - 10) {
        // alert("キーボード表示時の処理");
      } else {
        // alert("キーボード非表示時の処理");
        document.documentElement.style.setProperty(
          "--vh",
          `${window.innerHeight * 0.01}px`
        );
      }
    });
  } else {
    window.addEventListener("resize", () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    });
  }
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`
  );
  // 端末の回転
  // 端末の回転
  fromEvent(window, "orientationchange")
    .pipe(
      startWith(undefined),
      filter(() => isSP() && window.orientation != undefined)
    )
    .subscribe(() => {
      // stat.page.state.popups.Rot.value = window.orientation !== 0;
      // stat.page.state.isPortrait.value = window.orientation === 0;
    });
}
