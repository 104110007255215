import { Motion } from "@motionone/solid";

/**
 * @param { {
 * children: JSX.Element | ((item: NonNullable<T>) => JSX.Element),
 * initial?:number,
 * animate?:number,
 * exit?:number,
 * transition_duration?:number,
 * transition_easing?:number,
 * }} props
 * @returns {() => JSX.Element}
 */
function MotionBase({
  initial,
  animate,
  exit,
  transition_duration,
  transition_easing,
  ...stat
}) {
  return (
    <Motion.div
      initial={{ opacity: initial || 0 }}
      animate={{ opacity: animate || 1.0 }}
      exit={{ opacity: exit || 0 }}
      transition={{
        duration: transition_duration || 0.5,
        easing: transition_easing || "ease-in-out",
      }}
      class="top-0 left-0 right-0 bottom-0 m-auto h-full w-full"
    >
      {stat.children}
    </Motion.div>
  );
}
export default MotionBase;
