import myConfig from "../pageConfig";

/**
 *  ULRパラメーターを回収
 * */
export function getParam(paramName) {
  const url = new URL(window.location.href);
  const param = url.searchParams.get(paramName);
  if (param) {
    return param;
  } else {
    // console.error("パラメーターが取得できませんでした。");
    return undefined;
  }
}
/**
 *  クラス追加
 * */
export function addClass(elem, className) {
  if (!elem.classList.contains(className)) {
    elem.classList.add(className);
  }
}
/**
 *  クラス削除
 * */
export function removeClass(elem, className) {
  if (elem.classList.contains(className)) {
    elem.classList.remove(className);
  }
}
/**
 * フォントロード
 * */
export function loadFont(
  name,
  url,
  descriptors = {
    style: "normal",
    weight: "400",
  }
) {
  var newFont = new FontFace(name, `url(${url})`, descriptors);
  return new Promise((resolve) => {
    newFont
      .load()
      .then(function (loaded) {
        document.fonts.add(loaded);
        // console.log(document.fonts);
        // console.log(`${name}の読み込み完了`);
        resolve(loaded);
      })
      .catch(function (error) {
        console.log("FontError");
        return error;
      });
  });
}

/**
 * 対象下のノードを再帰的に制御する
 * @param {Node} node
 * @param {(node:Node)=>boolean} action
 * @returns
 */
export function RecursiveNode(node, action) {
  while (node != null) {
    if (!action(node)) return;
    // 子供を再帰
    RecursiveNode(node.firstChild, action);
    // 次のノードを探査
    node = node.nextSibling;
  }
}
/**
 * スマホかどうか
 * @returns
 */
export function isSP() {
  return (
    navigator.userAgent.match(/iPhone|iPad|Android.+Mobile/) != null ||
    (window.navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 &&
      "ontouchend" in document)
  );
}

const baseW = myConfig.AISize.portrait.w;
const baseH = myConfig.AISize.portrait.h;

export const vpMin = (value, baseWidth = baseW, baseHeight = baseH) => {
  return `min(${normalizeW(value, baseWidth)},${normalizeH(
    value,
    baseHeight
  )})`;
};
export const vpMax = (value, baseWidth = baseW, baseHeight = baseH) => {
  return `max(${normalizeW(value, baseWidth)},${normalizeH(
    value,
    baseHeight
  )})`;
};
export const normalizeW = (value = baseW, base = baseW) => {
  return `${(value * 100) / base}vw`;
};
export const normalizeH = (value = baseH, base = baseH) => {
  // return `${value*100/base}vh`;
  return `calc(var(--vh, 1vh) * ${(value * 100) / base})`;
};
export const clampedW = (value, base, threshold = value) => {
  const norm = normalizeW(value, base);
  if (value >= 0) {
    return `clamp(0px,${norm},${threshold}px)`;
  } else {
    return `clamp(${threshold}px,${norm},0px)`;
  }
};
export const clampedH = (value, base, threshold = value) => {
  const norm = normalizeH(value, base);
  if (value >= 0) {
    return `clamp(0px,${norm},${threshold}px)`;
  } else {
    return `clamp(${threshold}px,${norm},0px)`;
  }
};
