import { Observable, Subscription, from } from "rxjs";
import { createSignal, observable } from "solid-js";

/**
 * @template T
 */
export default class SignalProperty {
  /**
   * @private
   */
  _getter;
  /**
   * @private
   */
  _setter;

  /**
   * @private
   */
  _observable = undefined;

  /**
   * @param {T} val
   */
  constructor(val) {
    const [getter, setter] = createSignal(val);
    this._getter = getter;
    this._setter = setter;
    this._observable = from(observable(getter));
  }

  /**
   * @returns {T}
   */
  get value() {
    return this._getter();
  }

  /**
   * @param {T}
   */
  set value(val) {
    this._setter(val);
  }
  /**
   * @param {import("solid-js").Accessor<T>}
   */
  get getter() {
    return this._getter;
  }
  /**
   * @param {import("solid-js").Setter<T>}
   */
  get setter() {
    return this._setter;
  }

  /**
   * @returns {Observable<T>}
   */
  getObservable() {
    return this._observable;
  }
  /**
   * @returns {Subscription}
   */
  subscribe(observerOrNext) {
    return this._observable.subscribe(observerOrNext);
  }
}
