import MotionBase from "../../lib/MotionBase";
import AspectFitter from "../../lib/AspectFitter";
import { addClass, removeClass } from "../../common/common";
import { onMount } from "solid-js";
import "./map.css";

function Test({ stat }) {
  const nextPage = "Map";

  /**
   * タイトル後のページを設定する
   */
  onMount(() => {});

  return (
    <MotionBase>
      <div class="absoluteCenter show h-full w-full flexCenter bg-[#f1f0ea]">
        <div class="h-full w-full flexCenter fitScreenY">
          <div class="w-full h-full eGrid-row-[1108,830,278]">
            <div class="h-full w-full flex justify-center items-center flex-col">
              <p class="text-5xl font-noto font-black text-center m-12 leading-[1.4em]">
                <img
                  class="inline-block align-middle w-14 mr-2 mb-2"
                  src="./images/testImg/play.svg"
                  alt=""
                />
                ボタンを押して <br />
                テスト音声が流れるか <br />
                確認してみましょう
              </p>
              <div class="w-per-[614]">
                <AspectFitter w={614} h={484}>
                  <div class="flex justify-center items-center w-full h-full">
                    <button
                      class="btn w-per-[193] h-auto"
                      onclick={(ev) => {
                        removeClass(ev.target, "pointer-events-auto");
                        stat.pageState.popups.TestPop.value = true;
                        addClass(ev.target, "pointer-events-auto");
                      }}
                    >
                      <img
                        class="w-full h-auto"
                        src="./images/testImg/play.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </AspectFitter>
              </div>
            </div>
            <div class="flex justify-center items-center w-full h-full">
              <div class="flex justify-around items-center flex-col w-full h-[80%]">
                <div class="w-per-[502]">
                  <AspectFitter w={502} h={110}>
                    <button
                      class="btn3D btn-main w-full h-full"
                      onclick={(ev) => {
                        removeClass(ev.target, "pointer-events-auto");
                        stat.changePage(nextPage);
                      }}
                    >
                      <p class="text-customBase text-5xl font-noto font-black">
                        確認できた
                      </p>
                    </button>
                  </AspectFitter>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MotionBase>
  );
}

export default Test;
