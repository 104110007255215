import { onMount } from "solid-js";
import MotionBase from "../../lib/MotionBase";
import BlackPanel from "../../lib/BlackPanel";
import { vpMin } from "../../common/common";
import ScrollableArea from "../../lib/ScrollableArea";
import myConfig from "../../pageConfig";
import AspectFitter from "../../lib/AspectFitter";

// import "../../../css/modal.css";

const Terms = ({ ...stat }) => {
  onMount(() => {});
  return (
    <MotionBase>
      <BlackPanel>
        <div class="h-full w-full flex justify-center items-center">
          <div class="h-full w-full eGrid-row-[1108,933,175] fitScreenY">
            <div class="w-full h-full flex justify-center items-end">
              <div class="term h-per-[886,933] bg-white w-per-[364,392.5] rounded-2xl flexCenter">
                <div class="h-per-[90,100] w-[95%]  my-auto">
                  <ScrollableArea
                    scrollbarSize={vpMin(12)}
                    {...myConfig.mainScroll}
                  >
                    <div class="w-per-[90,100] m-5 text-black font-noto">
                      <p class="text-customBase fontSizeNormal-[48] font-noto font-black text-center my-6">
                        利用規約
                      </p>
                      <div>
                        <p class="title">
                          【コンテンツ利用上の注意】
                          <br />
                        </p>
                        <p class="subTitle">
                          ▼はじめにスマートフォンの設定をご確認ください。
                          <br />
                        </p>
                        <p class="main">
                          ・OS、ブラウザを最新バージョンにアップデートしている。
                          <br />
                          ・インターネットに接続している。
                          <br />
                        </p>
                        <p class="cation">
                          ※本コンテンツはスマートフォンコンテンツです。
                          <br />
                          パソコン・フィーチャーフォンではご参加いただけませんのでご注意ください。
                          <br />
                        </p>
                        <p class="subTitle">
                          ▼二次元バーコードを読み取る際、必ずSafari・Google
                          Chromeで開いてください。
                        </p>
                        <p class="main">
                          二次元バーコードを読み取ったあと、そのまま二次元バーコードリーダーアプリで開くと、正常に動作しない場合があります。
                        </p>
                        <p class="cation">
                          ※アプリ内ブラウザでの利用は、動作に支障が出る可能性があります。
                        </p>
                        <p class="subTitle">▼推奨環境</p>
                        <p class="main">
                          スマートフォン <br /> 【iPhone】iOS：16・17 ／
                          ブラウザ：Safari / Google Chrome（最新版）
                          【Android】OS：Android 12・13・14 以降 ／
                          ブラウザ：Google Chrome（最新版）
                        </p>
                        <p class="cation">
                          ※上記以外のブラウザでもご利用いただけるよう作成しておりますが、一部のブラウザでは表示が異なる場合がございます。
                          ※上記環境範囲内であっても、ブラウザとOSの組み合わせなどにより、動作や表示が正常におこなわれない可能性もございます。
                          ※各ブラウザの対応OS、ダウンロード、インストール方法等はブラウザ提供元である各社サイトをご確認ください。
                          ※提供元各社がサポートを打ち切っているOSおよびブラウザについては、確実な動作や表示を保証することができません。
                        </p>
                        <p class="subTitle">
                          ▼利用するツール、取得する情報及び利用目的
                        </p>
                        <p class="main">
                          本コンテンツでは、コンテンツの改善等を図るため、Google社が提供するサービスGoogle
                          Analyticsを利用してお客様の訪問情報を収集いたします。
                          Google社からその分析結果を受け取り、お客様の本サイトの訪問状況を把握します。
                          このサービスが収集・処理するデータに、お客様個人を特定する情報は含まれておりません。
                        </p>
                        <p class="subTitle">
                          本ツールの利用により取得する情報及びその利用目的の詳細は次のとおりです。
                        </p>
                        <p class="subTitle">[Google Analytics]</p>
                        <p class="main mb-4">
                          ツール提供者:Google Inc.
                          取得する情報:利用者のWebサイトの利用状況（アクセス状況、トラフィック、ルーティング等）
                          利用目的:利便性の向上やサイトの改善のため
                        </p>
                        <p class="cation">
                          ※なお、過去に当サイトを訪問された方が特定のページを訪問した際にお知らせ（広告）を配信することがあります。
                          利用者のWebサイトの利用状況については、Google社も取得し、利用します。
                          詳細については、以下のURLをご確認ください。
                        </p>
                        <p class="main my-8">
                          Google Analytics利用規約（外部サイトへリンク）
                          <a
                            target="_blank"
                            href="https://marketingplatform.google.com/about/analytics/terms/jp/"
                          >
                            https://marketingplatform.google.com/about/analytics/terms/jp/
                          </a>
                        </p>
                        <p class="main mb-8">
                          Google
                          のサービスを使用するサイトやアプリから収集した情報の
                          Google による使用（外部サイトへリンク）
                          <a
                            target="_blank"
                            href="https://policies.google.com/technologies/partner-sites?hl=ja"
                          >
                            https://policies.google.com/technologies/partner-sites?hl=ja
                          </a>
                        </p>
                        <p class="main mb-8">
                          Google社のプライバシーポリシーについては、以下のとおりです。
                          Googleのプライバシーポリシー（外部サイトへリンク）
                          <a
                            target="_blank"
                            href="https://policies.google.com/privacy?hl=ja"
                          >
                            https://policies.google.com/privacy?hl=ja
                          </a>
                        </p>
                        <p class="main mb-8">
                          Google
                          Analyticsに関する情報は、以下のサイトからも入手できます。
                          Google Analyticsに関する詳細情報（外部サイトへリンク）
                          <a
                            target="_blank"
                            href="https://support.google.com/analytics/answer/6004245?hl=ja&ref_topic=2919631"
                          >
                            https://support.google.com/analytics/answer/6004245?hl=ja&ref_topic=2919631
                          </a>
                          アナリティクスヘルプ　＞　データのプライバシーとセキュリティ　＞　データの保護
                        </p>
                        <p class="main  mb-4">
                          本コンテンツを利用することでcookie、およびGoogle
                          Analyticsの使用に許可を与えたものとみなします。
                        </p>
                        <p class="cation">
                          ※なお、Google
                          Analytics利用によるお客様の情報収集を停止することも可能です。Googleアナリティクス
                          オプトアウトより無効化を行ってください。
                        </p>
                      </div>
                    </div>
                  </ScrollableArea>
                </div>
              </div>
            </div>
            <div class="w-full h-full flex justify-center items-center">
              <div class="w-per-[280] h-full flex justify-end items-center">
                <AspectFitter w={280} h={97}>
                  <button
                    type="button"
                    class="btn3D btn-main w-full h-full flex justify-center items-center"
                    onClick={(ev) => {
                      stat.pageState.popups.Terms.value = false;
                    }}
                  >
                    <div class="w-[70%] h-full flex justify-between items-center">
                      <div class=" bg-customBase rounded-full w-min p-1">
                        <svg
                          class="h-12 w-12 text-white"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          {" "}
                          <line x1="18" y1="6" x2="6" y2="18" />
                          <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                      </div>
                      <p class=" text-black font-bold text-4xl tracking-widest">
                        閉じる
                      </p>
                    </div>
                  </button>
                </AspectFitter>
              </div>
            </div>
          </div>
        </div>
      </BlackPanel>
    </MotionBase>
  );
};
export default Terms;
