import AspectFitter from "./AspectFitter";

/**
 *　ボタン要素
 * ※Widthを目的値に合わせたDiv内に使用してください
 * @param { {
 * size:{w:number,h:number,},
 * buttonSetting:{bgColor:string,borderColor:string,borderW:number},
 * clickEvent:React.MouseEvent<HTMLButtonElement, MouseEvent>,
 * id:string,
 * children: JSX.Element | ((item: NonNullable<T>) => JSX.Element)
 * }} props
 * @returns {() => JSX.Element}
 */
function EllipseButtonBase3({
  size,
  buttonSetting,
  clickEvent,
  radius,
  id = "",
  ...stat
}) {
  return (
    <AspectFitter w={size.w} h={size.h} {...stat}>
      <button
        id={id}
        type="button"
        class="w-full h-full border-solid btn pointer-events-auto"
        onClick={clickEvent}
        style={`
        background-color:${buttonSetting.bgColor};
        border-color:${buttonSetting.borderColor};
        border-width:${buttonSetting.borderW / 16}rem;
        border-radius:${radius}rem;
        `}
      >
        <div class="absolute left-0 top-0 w-full h-full table pointer-events-none">
          {stat.children}
        </div>
      </button>
    </AspectFitter>
  );
}
export default EllipseButtonBase3;
