import pageConfig from "./common/pageConfig";

import Loading from "./components/page/Loading";
import Title from "./components/page/Title";
import Check from "./components/page/Check";
import Test from "./components/page/Test";
import Map from "./components/page/Map";

import Music from "./components/popup/Music";
import Terms from "./components/popup/Terms";
import Check2 from "./components/page/Check2";
import TestPop from "./components/popup/TestPop";
import Complete from "./components/popup/Complete";
import LoadPop from "./components/popup/LoadPop";

// ページ構成
export const pages = {
  Loading,
  Title,
  Check,
  Test,
  Map,
};
// ポップアップ
export const modals = {
  Terms,
  Music,
  TestPop,
  Complete,
  LoadPop,
};
// 初めのページ
export const BEGIN_PAGE = "Loading";
